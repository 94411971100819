import React, {Component} from 'react';

import {Modal} from 'react-bootstrap';

import './Field.css';
import connect from "react-redux/es/connect/connect";

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faTimesCircle} from '@fortawesome/fontawesome-free-solid';
import { Trans} from 'react-i18next';
import { SHOW_ALL_PLAYERS_FOR_ALL_POS } from '../../util/Constants';

class Field extends Component {

    constructor(props) {

        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseWarning = this.handleCloseWarning.bind(this);
        this.handleSelectPlayer = this.handleSelectPlayer.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.calculatePositionsStyles = this.calculatePositionsStyles.bind(this);

        let ps = this.calculatePositionsStyles();

        this.state = {
            show: false,
            selectedPos: null,
            positionStyles: ps,
            selectedPlayers: [],
            replacing: false,
            playersList: null,
            intervalId: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.competition.dataAvailable && this.props.competition.dataAvailable) {
            // this.setFieldFromDb();
            window.addEventListener('resize', this.updateWindowDimensions);
        }
        if (!prevProps.auth.votes && this.props.auth.votes) {
            const {competition, comp} = this.props;
            if (comp) {
                const {currentRound} = competition;
                const {field: compField} = comp;
                const intervalField = currentRound && currentRound.field;
                const field = (intervalField && Object.entries(intervalField).length > 0 && intervalField.constructor === Object)
                        ? intervalField : compField;
                const positions = field.lines.reduce((acc, l) => acc.concat(l.positions), []);
                const availablePlayers = comp.intervals.reduce((acc, i) => acc.concat(i.players), []);
                const selPl = this.props.auth.votes.reduce((acc, v) => {
                    if (acc && !acc.find(i => i === v.idInterval)) {
                        if (!acc[v.idInterval]) {
                            acc[v.idInterval] = [];
                        }
                        acc[v.idInterval].push({
                            player: availablePlayers.find(ap => ap.id === v.idPlayer),
                            position: positions.find(p => p.id === v.idPosition)
                        })
                    }
                    return acc;
                }, []);

                this.setState({
                    selectedPos: null,
                    selectedPlayers: selPl,
                    show: false,
                });

                this.props.onSelectionChange(selPl);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    setFieldFromDb = () => {
        if (!this.props.comp) {
            return {
                positions: [],
                totalPlayers: 0
            };
        }
        const {field} = this.props.comp;
        const {currentRound} = this.props.competition;
        let nPos = 0;
        if (currentRound && currentRound.field && currentRound.override) {
            nPos = currentRound.field.lines.reduce((acc, l) => acc + l.positions.length, 0);
        } else if (field) {
            nPos = field.lines.reduce((acc, l) => acc + l.positions.length, 0);
        }
        const fieldData = {
            positions: this.calculatePositionsFromComp(),
            totalPlayers: nPos
        };
        // this.setState(fieldData);
        return fieldData;
    }

    calculatePositionsFromComp = () => {

        const posStyles = this.calculatePositionsStyles();

        const {field: generalField, fieldBg} = this.props.comp;
        const {currentRound} = this.props.competition;
        if (!generalField && (!currentRound || !currentRound.field)) {
            return [];
        }
        const field = (currentRound && currentRound.override && currentRound.field) || generalField;
        const defaultPositionStyles = {
            width: `148px`,
            height: `205px`,
            margin: `16px`,
        }
        const fieldStyle = {};
        if (fieldBg) {
            fieldStyle.backgroundImage = `url(${fieldBg.file})`;
        }
        return [
            {
                type: 'container',
                className: 'field',
                style: fieldStyle,
                places: field.lines.map(l => {
                    const ret = [];
                    if (l.show) {
                        ret.push({
                            type: 'title',
                            text: l.name
                        });
                    }
                    ret.push({
                        type: 'line',
                        places: l.positions.map(p => {
                            const marginLeft = p.marginLeft ? `${parseInt(p.marginLeft * posStyles.marginMultiplier)}px` : null;
                            const marginRight = p.marginRight ? `${parseInt(p.marginRight * posStyles.marginMultiplier)}px` : null;
                            const style = {
                                ...defaultPositionStyles
                            };
                            if (marginLeft) {
                                style.marginLeft = marginLeft;
                            }
                            if (marginRight) {
                                style.marginRight = marginRight;
                            }
                            return {
                                type: 'position',
                                id: p.id,
                                text: p.name,
                                selectedPlayers: p.selectedPlayersAsObject,
                                style
                            }
                        })
                    });
                    return ret;
                }).reduce((acc, val) => acc.concat(val), []),
            }
        ];
    }

    calculatePositionsStyles = () => {

        let s = {
            width: 120,
            height: 64,
            margin: 10,
            marginMultiplier: 1
        };

        let w = this.state && this.state.width ? this.state.width : window.innerWidth;

        s.padding = s.margin * 5;
        if (w <= 570) {
            s.width = 100;
            s.padding = s.margin * 3;
        }
        if (w <= 450) {
            s.width = 90;
            s.padding = s.margin * 2;
        }

        if (w <= 450) {
            s.width = 80;
            s.margin = 4;
            s.padding = s.margin;
            s.marginMultiplier = .5;
        }

        return s;
    }

    updateWindowDimensions = () => {

        let ps = this.calculatePositionsStyles();

        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            positionStyles: ps,
            // positions: this.calculatePositionsFromComp(),
            notLoggedIn: false,
        });
    }

    handleClose = () => {
        this.setState({
            show: false
        });
    }

    handleCloseWarning = () => {
        this.setState({
            notLoggedIn: false
        });
    }

    handleShow = (selectedPos, replacing) => {

        if (!this.props.loggedIn) {
            this.setState({
                notLoggedIn: true
            });
            return;
        }

        const {selectedPlayers} = this.state;
        const {comp, competition} = this.props;
        const {currentRound} = competition;

        if (!comp)
            return;

        if (replacing) {

            const newSelectedPlayers = [];
            newSelectedPlayers[currentRound.id] =
                selectedPlayers[currentRound.id].filter(sp => sp.position.id !== selectedPos.id);

            this.setState({
                selectedPos: null,
                show: false,
                replacing: false,
                selectedPlayers: newSelectedPlayers
            });

            return;
        }

        let playersList = null;
        let availablePlayers = currentRound && currentRound.players;
        if (currentRound.field && currentRound.override) {
            availablePlayers = availablePlayers.filter(ap => {
                return ap.idLinePosition === selectedPos.id
            })
            availablePlayers = availablePlayers.reduce((acc, el) => {
                if (!acc.find(a => a.id === el.id)) {
                    acc.push(el);
                }
                return acc;
            }, []);
        } else {
            availablePlayers = availablePlayers.filter(p => {
                if (!selectedPlayers[currentRound.id] || (SHOW_ALL_PLAYERS_FOR_ALL_POS
                    && SHOW_ALL_PLAYERS_FOR_ALL_POS.includes(comp.id)))
                    return true;
                const selectedPlayersIds = selectedPlayers[currentRound.id].map(s => s.player.id);
                return !selectedPlayersIds.find(s => s === p.id)
            });
            availablePlayers = availablePlayers.reduce((acc, el) => {
                if (!acc.find(a => a.id === el.id)) {
                    acc.push(el);
                }
                return acc;
            }, []);
        }
        if (selectedPos && availablePlayers) {
            playersList = availablePlayers.map(p => {
                return (
                    <div key={p.id} className={'player'} onClick={() => this.handleSelectPlayer(p)}>
                        <div className={'pl-head-cont'} style={{backgroundImage: `url(${p.headshot})`}}></div>
                        <div className={'pl-name'}>{`${p.firstname}`} <span>{`${p.lastname}`}</span></div>
                    </div>
                );
            })
        }

        this.setState({
            selectedPos: selectedPos,
            show: true,
            replacing: replacing,
            playersList
        });
    }

    handleSelectPlayer = (player) => {

        if (!this.props.comp)
            return;

        const {competition} = this.props;
        const {currentRound} = competition;

        let selPl = this.state.selectedPlayers.slice();
        if (!selPl[currentRound.id]) {
            selPl[currentRound.id] = [];
        }
        selPl[currentRound.id].push({
            player: player,
            position: this.state.selectedPos
        });

        this.setState({
            selectedPos: null,
            selectedPlayers: selPl,
            show: false,
        });

        this.props.onSelectionChange(selPl);
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    renderIniBtns = () => {

        const {comp} = this.props;
        
        return (<div className='login-options'>
            <div
                className='button margin login-btn' onClick={() => {this.handleCloseWarning(); this.props.onTriggerRegisterLogin('login'); this.scrollToTop(); }}>
                <Trans i18nKey="login" />
            </div>
            <div
                 className='button red_btn' onClick={() => {this.handleCloseWarning(); this.props.onTriggerRegisterLogin('register'); this.scrollToTop(); }}>
                <Trans i18nKey="register" />
            </div>
        </div>)
    }

    renderFieldPart(pos, styles) {
        const {competition, comp} = this.props;

        const {currentRound} = competition;

        switch (pos.type) {

            case 'title': {

                return <div style={{color: this.props.comp.primaryColor ? this.props.comp.primaryColor : ''}} key={JSON.stringify(pos)}
                            className={`${pos.type} ${pos.className ? pos.className : ''}`}
                            // style={{color: comp.primaryColor}}
                >{pos.text}</div>;
            }
            case 'container': {

                return <div key={JSON.stringify(pos)} className={pos.className} style={pos.style}>
                    {pos.places.map(op => this.renderFieldPart(op, styles))}
                </div>;
            }
            case 'line': {
                const nPlaces = pos.places.length;
                let lineWid = pos.places.reduce((accumulator, el) => {
                    const wid = parseInt(el.style.width.replace("px", ""));
                    let mLeft = parseInt(el.style.margin.replace("px", ""));
                    if (el.style && el.style.marginLeft) {
                        mLeft = parseInt(el.style.marginLeft.replace("px", ""));
                    }
                    let mRight = parseInt(el.style.margin.replace("px", ""));
                    if (el.style && el.style.marginRight) {
                        mRight = parseInt(el.style.marginRight.replace("px", ""));
                    }
                    return accumulator + wid + mLeft + mRight;
                }, 0);
                const linePlaces = pos.places.map(op => this.renderFieldPart(op, styles));

                return <div key={JSON.stringify(pos)} className={`${pos.type} ${pos.className ? pos.className : ''}`}
                            style={{width: `${lineWid}px`}}
                >
                    {linePlaces}
                </div>;
            }
            case 'column': {
                const linePlaces = pos.places.map(op => this.renderFieldPart(op, styles));

                return <div key={JSON.stringify(pos)} className={pos.type}
                            style={{width: `${styles.width + styles.margin}px`}}
                >
                    {linePlaces}
                </div>;
            }
            case 'image': {

                return <div key={JSON.stringify(pos)} className={`${pos.className}-cont`}>
                    <img className={pos.className} src={pos.src}/>
                </div>;
            }
            case 'position': {
                let used = undefined;
                if (this.state.selectedPlayers[currentRound.id]) {
                    used = this.state.selectedPlayers[currentRound.id].find(p => p.position && pos.id === p.position.id);
                }

                if (!used) {

                    return (
                        <div key={JSON.stringify(pos)} className={`${pos.type} ${pos.className ? pos.className : ''}`}
                             onClick={() => this.handleShow(pos, false)}
                             style={{...pos.style,borderColor: (this.props.comp.borderColor ? this.props.comp.borderColor : '#ffffff'),backgroundColor:(this.props.comp.backgroundColor?this.props.comp.backgroundColor:'')}}
                        ><div style={{color: this.props.comp.primaryColor ? this.props.comp.primaryColor : ''}}  className="position-placeholder"><span className="position-plus"></span> {pos.type && !pos.hideText ? pos.text : ''}</div></div>)

                } else {

                    let usedBy = used.player;

                    return (
                        <div key={JSON.stringify(pos)}
                             className={`${pos.type} ${pos.className ? pos.className : ''} used`}
                             onClick={() => this.handleShow(pos, true)}
                             style={{...pos.style,borderColor: (this.props.comp.borderColor ? this.props.comp.borderColor : '#ffffff'),backgroundColor:(this.props.comp.backgroundColor?this.props.comp.backgroundColor:'')}}
                        >
                            <div className={'remove'} style={{
                                width: styles.width - 2,
                                height: this.state.positionStyles.height - 2
                            }}
                            >
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </div>
                            <div className={'selected-player'} style={{borderColor: this.props.comp.borderColor ? this.props.comp.borderColor : '#ffffff',backgroundColor:this.props.comp.backgroundColor?this.props.comp.backgroundColor:''}}>
                                <div className={'head'} style={{backgroundImage: `url(${usedBy.headshot})`}}></div>
                                <div className={'name'} >
                                    <div className={'firstname custom-vote-colour'} style={{color: this.props.comp.primaryColor ? this.props.comp.primaryColor : '#ffffff'}}>{usedBy.firstname}</div>
                                    <div className={'lastname custom-vote-colour'} style={{color: this.props.comp.primaryColor ? this.props.comp.primaryColor : '#ffffff'}}>{usedBy.lastname}</div>
                                </div>
                                <div className={'role'}>{pos.type && !pos.hideText ? pos.text : ''}</div>
                            </div>
                        </div>)
                }
            }
        }
    }

    camelize = (str) => `${str.substring(0, 1).toUpperCase()}${str.substring(1).toLowerCase()}`;

    renderField = (positions, positionStyles) => {
        return positions.map(pos => this.renderFieldPart(pos, positionStyles));
    }

    render() {

        const {selectedPos, playersList} = this.state;
        const fieldData = this.setFieldFromDb();

        return (
            <div
                className={'field-container'}
            >
                {this.renderField(fieldData.positions, this.state.positionStyles)}
                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName={'playersModal'}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className={'lightbox-heading'}>
                                <h4>{selectedPos ? selectedPos.text : ''}</h4>
                                <p><Trans i18nKey="select_preferred_player" /></p>
                            </div>
                            {/* <div className={'lightbox-for'}>
                                <div className={'the-place'}>
                                    {selectedPos ? selectedPos.text : ''}
                                </div>
                                <div className={'the-legend'}>
                                    Choose a player for<br/>
                                    position: {selectedPos ? selectedPos.text : ''}
                                </div>
                            </div> */}
                            <div className={'lightbox-list'}>
                                {playersList}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.notLoggedIn} onHide={this.handleCloseWarning} dialogClassName={'warningModal'}>
                    <Modal.Body>
                        <div className='warningCopy'>
                        <Trans i18nKey="login_to_participate" />
                            <br/>
                        </div>
                        <div className="login-register-btn">
                            {this.renderIniBtns()}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        comp: things.competition.data,
        auth: things.auth,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Field);
