import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Trans,withTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';


import './TotalLeaderboard.css';
import { getLeaderboardStandings, getTippingStandings } from '../../actions/CompetitionActions';
import TotalLeaderboardItems from './TotalLeaderboardItems';

class TotalLeaderboard extends Component {

    constructor(props) {

        super(props);
        this.state = {
            page: 0,
            limit: 20,
            pageLoading: false,
            previousEnabled: false,
            nextEnabled: false,
        };

        this.goToNextPage = this.goToNextPage.bind(this);
        this.goToPrevPage = this.goToPrevPage.bind(this);
    }

    componentDidMount() {
        const secondsToChangePage = 30;
        if (this.props.showOnlyLeaderboard) {
            setInterval(() => {
                if (this.props.competition.nextEnabled) {
                    this.goToNextPage();
                } else {
                    this.setState({
                        page: 0
                    });
                    this.goToPage(this.state.page);
                }
            }, secondsToChangePage*1000);
        }
    }

    goToPage(page) {
        if (this.props.competition.data.type == 'tipping') {
            this.props.dispatch(
                getTippingStandings(
                    this.props.competition.data.id,
                    localStorage.getItem('token'),
                    { 
                        page,
                        limit: this.props.showOnlyLeaderboard ? this.state.limit : 15,
                        authenticated: (!this.props.showOnlyLeaderboard && this.props.loggedIn)
                    },
                    this.props.dispatch
                )
            );
        } else {
            this.props.dispatch(
                getLeaderboardStandings(
                    this.props.competition.data.id,
                    localStorage.getItem('token'),
                    { 
                        page,
                        limit: this.props.showOnlyLeaderboard ? this.state.limit : 15,
                        authenticated: (!this.props.showOnlyLeaderboard && this.props.loggedIn)
                    },
                    this.props.dispatch
                )
            );
        }
        
    }

    goToNextPage() {
        if (!this.props.competition.nextEnabled) {
            return;
        }
        this.setState({
            page: ++this.state.page
        });
        this.goToPage(this.state.page);
    }

    goToPrevPage() {
        if (!this.props.competition.previousEnabled) {
            return;
        }
        this.setState({
            page: (this.state.page > 0) ? --this.state.page : 0
        });
        this.goToPage(this.state.page);
    }

    render() {

        let fullLeaderboards = this.props.competition.tippingLeaderboards;
        // fullLeaderboards = {"page":0,"limit":15,"total":10,"items":[{"rank":1,"username":"John","score":15},{"rank":2,"username":"Mark","score":15},{"rank":3,"username":"Rita21","score":14},{"rank":4,"username":"Madison","score":12},{"rank":5,"username":"Jeny","score":12},{"rank":6,"username":"Harrett","score":12},{"rank":7,"username":"Mendoza","score":11},{"rank":8,"username":"Wilda","score":11},{"rank":9,"username":"Randomentry","score":10},{"rank":10,"username":"Bradley","score":9},{"rank":11,"username":"Schneider","score":8},{"rank":12,"username":"Theodora","score":8}]};
        // let fullLeaderboards1 = {"page":0,"limit":15,"total":5,"items":[{"rank":13,"username":"Clifford","score":8},{"rank":14,"username":"Osborne367","score":8},{"rank":15,"username":"Judy","score":7},{"rank":16,"username":"Erickson","score":7},{"rank":17,"username":"Henry","score":6},{"rank":18,"username":"Madeline","score":5},{"rank":19,"username":"Hodgson","score":5},{"rank":20,"username":"Wally","score":4},{"rank":21,"username":"Doran","score":3},{"rank":22,"username":"Morton","score":2},{"rank":23,"username":"Esmond","score":1},{"rank":24,"username":"Duncan","score":1}]};
        // fullLeaderboards = {"page":0,"limit":15,"total":24,"items":[{"rank":1,"username":"John","score":15},{"rank":2,"username":"Mark","score":15},{"rank":3,"username":"Rita21","score":14},{"rank":4,"username":"Madison","score":12},{"rank":5,"username":"Jeny","score":12},{"rank":6,"username":"Harrett","score":12},{"rank":7,"username":"Mendoza","score":11},{"rank":8,"username":"Wilda","score":11},{"rank":9,"username":"Randomentry","score":10},{"rank":10,"username":"Bradley","score":9},{"rank":11,"username":"Schneider","score":8},{"rank":12,"username":"Theodora","score":8},{"rank":13,"username":"Clifford","score":8},{"rank":14,"username":"Osborne367","score":8},{"rank":15,"username":"Judy","score":7},{"rank":16,"username":"Erickson","score":7},{"rank":17,"username":"Henry","score":6},{"rank":18,"username":"Madeline","score":5},{"rank":19,"username":"Hodgson","score":5},{"rank":20,"username":"Wally","score":4},{"rank":21,"username":"Doran","score":3},{"rank":22,"username":"Morton","score":2},{"rank":23,"username":"Esmond","score":1},{"rank":24,"username":"Duncan","score":1}]};

        const leaderboardLength = fullLeaderboards.items && fullLeaderboards.items.length;
        const panelItemSize = 10;

        const showTwoPanels = (leaderboardLength > panelItemSize);

        let firstPanelData = fullLeaderboards.items;
        let secondPanelData = [];
        if (showTwoPanels) {
            firstPanelData = fullLeaderboards.items.slice(0, panelItemSize);
            secondPanelData = fullLeaderboards.items.slice(panelItemSize, (panelItemSize*2));
        }

        const yourPosition = localStorage.getItem('tippingPosition');
        const yourScore = localStorage.getItem('tippingScore');

        console.log('your score', yourPosition);

        const {comp,t} = this.props;
        const currentRound = this.props.competition.currentRound;

        if(!currentRound) {
            return null;
        }

        // fullLeaderboards = {"page":0,"limit":2,"total":6,"items":[{"rank":1,"username":"oldtest9@getnada.com","score":1},{"rank":2,"username":"siva@mail.com","score":1}]};
        // let listDiv = {}

        const theEnd = moment(new Date(currentRound ? currentRound.end : 0)).format('dddd Do, h:mm a');

        return (
            ( !this.props.showOnlyLeaderboard ?
            <div className='total-leaderboard' style={{color: comp ? comp.primaryColor : ''}}>
                <div
                    // style={{backgroundColor: comp ? comp.secondaryColor : '', color: comp ? comp.primaryColor : ''}}
                    className={`lb-head ${this.props.widget ? 'widget' : ''}`}><Trans i18nKey="overall_leaderboard" />
                </div>
                { yourPosition != null && yourPosition >= 0 && yourScore != null && yourScore >= 0 ?
                <div className={`current-position`}>
                    Your current position: {yourPosition}<span className='marker'>|</span>Your current score: {yourScore}
                </div>
                    : null}
                <div className='lb-body'>
                    {fullLeaderboards && fullLeaderboards.items && fullLeaderboards.items.length > 0
                        ? <table className='leaderboard-table'><tbody>{fullLeaderboards && fullLeaderboards.items && fullLeaderboards.items.map(l =>
                            (
                                <tr className={`lb-place ${l.rank % 2 ? 'odd-row' : 'even-row'}`} key={JSON.stringify(l)}>
                                    <td className='player'>{`${l.rank}`}</td>
                                    <td className='player'>{`${l.username}`}</td>
                                    <td className='points'>{l.score}</td>
                                </tr>
                            ))}
                            </tbody></table>
                        : this.props.competition.pageLoading
                            ? (<div className='lb-place center'><Trans i18nKey="loading" /></div>)
                            : (<div
                                style={{color: comp ? comp.primaryColor : ''}}
                                className='lb-place center'>
                                {currentRound ?
                                    `${t('voting_closes_on')} ${theEnd}.` : ''}
                            </div>)}
                </div>
                {!this.props.competition.pageLoading
                    ? (<div className='pagination'>
                        <i className={`fa fa-chevron-left previous fa-lg ${this.props.competition.previousEnabled ? '' : 'disabled'}`}
                            onClick={this.goToPrevPage} aria-hidden="true"></i>
                        <i className={`fa fa-chevron-right next fa-lg ${this.props.competition.nextEnabled ? '' : 'disabled'}`}
                            onClick={this.goToNextPage} aria-hidden="true"></i>
                    </div>)
                    : null}
            </div>
            :
            <div className='total-leaderboard-expanded'>
                {comp.logoBg ? (
                    <div className={'logos-cont'}
                            style={{
                                margin: '0 auto'
                            }}>
                        <img src={comp.logoBg.file} 
                            style={{
                                display: 'block',
                                margin: '0 auto'
                            }}
                            className={'pl-logo logo-image'} width='200px'/>
                        <div
                            style={{
                                margin: '0 auto'
                            }}>
                        <h3 style={{
                                color: comp ? comp.primaryColor : '',
                                display: 'block',
                                textAlign: 'center',
                                marginTop: '15px',
                                fontSize: '27px'
                            }}
                            dangerouslySetInnerHTML={{__html: comp.detail}} className={'mini-site-title'}/>
                            {/* dangerouslySetInnerHTML={{__html: comp.detail}} className={'mini-site-title'}/> */}
                    </div>
                    </div>
                ) : null}

                <TotalLeaderboardItems leaderboardData={firstPanelData} showTwoPanels={showTwoPanels} panelNum={1} />

                {showTwoPanels ? (
                    <TotalLeaderboardItems leaderboardData={secondPanelData} showTwoPanels={showTwoPanels} panelNum={2} />
                ) : null}

                {!this.props.competition.pageLoading
                    ? (<div className='pagination'>
                        <i className={`fa fa-chevron-left previous fa-lg ${this.props.competition.previousEnabled ? '' : 'disabled'}`}
                            onClick={this.goToPrevPage} aria-hidden="true"></i>
                        <i className={`fa fa-chevron-right next fa-lg ${this.props.competition.nextEnabled ? '' : 'disabled'}`}
                            onClick={this.goToNextPage} aria-hidden="true"></i>
                    </div>)
                    : null}
            </div>
            )
        );
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        comp: things.competition.data,
        competition: things.competition,
        locale: things.locale,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TotalLeaderboard));
