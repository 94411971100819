import {trackApiCall} from '../util/api_calls/ProjectApiCalls';
import Fingerprint from 'fingerprintjs';

const trackSession = (idCompetition, dispatch) => {

    trackApiCall(
        idCompetition,
        {type: "session", fingerprint: new Fingerprint().get()},
        payload => {
            dispatch(trackSessionFinish(payload))
        },
        error => {
            dispatch(trackSessionError(error))
        }
    );

    return {
        type: 'TRACK_SESSION_START',
        payload: {}
    }
}

const trackSessionFinish = payload => {

    return {
        type: 'TRACK_SESSION_FINISH',
        payload: {
            ...payload
        }
    }
}

const trackSessionError = error => {

    console.error("error", error);
    let payload = {}
    if (error.constructor.name === 'Error') {
        // In case 500 is received I don't want to show server's raw error.
        payload = {
            errorMessages: ['There was an error, please contact support.']
        };
    } else {
        payload = {...error};
    }
    return {
        type: "TRACK_SESSION_ERROR",
        payload: payload
    }
}

const trackVote = (idCompetition, idVote, idUser, flakeId, dispatch) => {

    trackApiCall(
        idCompetition,
        {type: "vote", fingerprint: new Fingerprint().get(), idVote, idUser, sessionFlakeId: flakeId},
        payload => {
            dispatch(trackVoteFinish(payload))
        },
        error => {
            dispatch(trackVoteError(error))
        }
    );

    return {
        type: 'TRACK_VOTE_START',
        payload: {}
    }
}

const trackVoteFinish = payload => {

    return {
        type: 'TRACK_VOTE_FINISH',
        payload: {
            ...payload
        }
    }
}

const trackVoteError = error => {

    console.error("error", error);
    let payload = {}
    if (error.constructor.name === 'Error') {
        // In case 500 is received I don't want to show server's raw error.
        payload = {
            errorMessages: ['There was an error, please contact support.']
        };
    } else {
        payload = {...error};
    }
    return {
        type: "TRACK_VOTE_ERROR",
        payload: payload
    }
}

const trackOptin = (idCompetition, idUser, flakeId, dispatch) => {

    trackApiCall(
        idCompetition,
        {type: "optin", fingerprint: new Fingerprint().get(), idUser, sessionFlakeId: flakeId},
        payload => {
            dispatch(trackOptinFinish(payload))
        },
        error => {
            dispatch(trackOptinError(error))
        }
    );

    return {
        type: 'TRACK_OPTIN_START',
        payload: {}
    }
}

const trackOptinFinish = payload => {

    return {
        type: 'TRACK_OPTIN_FINISH',
        payload: {
            ...payload
        }
    }
}

const trackOptinError = error => {

    console.error("error", error);
    let payload = {}
    if (error.constructor.name === 'Error') {
        // In case 500 is received I don't want to show server's raw error.
        payload = {
            errorMessages: ['There was an error, please contact support.']
        };
    } else {
        payload = {...error};
    }
    return {
        type: "TRACK_OPTIN_ERROR",
        payload: payload
    }
}

export {trackSession, trackVote, trackOptin}
