const initialState = {
  flakeId: undefined,
  idVote: undefined,
  idOptin: undefined,
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'TRACK_SESSION_START':
      return {
        ...state,
        flakeId: undefined
      }
    case 'TRACK_SESSION_FINISH':
      console.log("TRACK_SESSION_FINISH", action.payload);
      return {
        ...state,
        flakeId: action.payload.data && action.payload.data.result && action.payload.data.result.flakeId
      }
    case 'TRACK_SESSION_ERROR':
      return {
        ...state,
        flakeId: undefined
      }
    case 'TRACK_VOTE_START':
      return {
        ...state,
        idVote: undefined
      }
    case 'TRACK_VOTE_FINISH':
      console.log("TRACK_VOTE_FINISH payload", action.payload)
      return {
        ...state,
        idVote: action.payload.data && action.payload.data.result && action.payload.data.result.idVote
      }
    case 'TRACK_VOTE_ERROR':
      return {
        ...state,
        idVote: undefined
      }
    case 'TRACK_OPTIN_START':
      return {
        ...state,
        idOptin: undefined
      }
    case 'TRACK_OPTIN_FINISH':
      console.log("TRACK_OPTIN_FINISH payload", action.payload)
      return {
        ...state,
        idOptin: action.payload.data && action.payload.data.result && action.payload.data.result.idOptin
      }
    case 'TRACK_OPTIN_ERROR':
      return {
        ...state,
        idOptin: undefined
      }
    default:
      return state
  }
}
