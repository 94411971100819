import React, {Component} from 'react';
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Trans,withTranslation} from 'react-i18next';
import {
    Checkbox,
    ControlLabelButton,
    FormControl,
    FormGroup,
    Radio,
    HelpBlock,
    Modal,
    DropdownButton,
    MenuItem
} from 'react-bootstrap';

import {faFacebookF, faTwitter} from '@fortawesome/fontawesome-free-brands/index'

// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookLogin from './FacebookLogin';

import './Login.css';
import {
    forgot,
    getEmail,
    getPassword,
    isSpecialComp,
    reset,
    signIn,
    signInFace,
    signInTwitter,
    signOff,
    signUp,
    webhookRegister
} from "../../actions/AuthActions";
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/fontawesome-free-solid/index'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import moment from 'moment';
import { SHOW_USERNAME_NON_TIPPING } from '../../util/Constants';
import RoundSelector from './RoundSelector';


class VoteStatus extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loginEnabled: false,
            forgotEnabled: false,
            registerEnabled: false,
            loggedIn: props.auth.loggedIn,
            user: props.auth.user,
            username: '',
            forgotEmail: '',
            forgotNewPass: '',
            closeReset: false,
            charLengthCustom: 'Characters Left: 2000/2000',
            charLengthCustomComm: [],
            optin: {
                username: '',
                firstname: '',
                lastname: '',
                email: '',
                mobile: {
                    countryData: {},
                    number: '',
                    status: false,
                    value:''
                },
                address: '',
                postcode: '',
                state: '',
                other: '',
                password: '',
                tnc: false,
                marketing: false,
                member: undefined,
                memberYes: undefined,
                memberNo: undefined,
                others: [],
                dob: '',
                confirmAdult: false
            },
            errStates: {
                firstname: false,
                lastname: false,
                email: false,
                mobile: false,
                address: false,
                postcode: false,
                state: false,
                other: false,
                password: false,
                tnc: false,
                dob: false,
                confirmAdult: false
            }
        }

        this.preferredCountries = ['au','gb','us','br','ca','cn','de','in','id','my','nl','nz','pt','sg','es','th','vn','kr'];

        this.handleLogin = this.handleLogin.bind(this);
        this.loginRoute = this.loginRoute.bind(this);
        this.handleLoginFacebook = this.handleLoginFacebook.bind(this);
        this.toRegister = this.toRegister.bind(this);
        this.toLogin = this.toLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeNonOptin = this.handleChangeNonOptin.bind(this);
        this.registerRoute = this.registerRoute.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleRegisterTipping = this.handleRegisterTipping.bind(this);
        this.handleAcceptTnc = this.handleAcceptTnc.bind(this);
        this.handleSelectOptinOtherOptionSingle = this.handleSelectOptinOtherOptionSingle.bind(this);
        this.handleSelectOptinOtherOption = this.handleSelectOptinOtherOption.bind(this);
        this.handleChangeOptinOther = this.handleChangeOptinOther.bind(this);
        this.handleChangeOptinOtherComm = this.handleChangeOptinOtherComm.bind(this);
        this.handleChangeOptinOtherDate = this.handleChangeOptinOtherDate.bind(this);
    }

    componentDidMount() {
        if (this.props.onRef)
            this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef)
            this.props.onRef(undefined);
    }

    handleChangeNonOptin = (field, e) => {
        let newState = {...this.state};
        newState[field] = e.target.value;
        console.log("newState", newState);
        this.setState(newState);
    }

    handleAcceptTnc = () => {
        let newState = {...this.state.optin};
        newState.tnc = !newState.tnc;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    handleAcceptMarketing = () => {
        let newState = {...this.state.optin};
        newState.marketing = !newState.marketing;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    selectMembership = (value) => {
        let newState = {...this.state.optin};
        newState.member = value;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    memberYes = (e) => {
        let newState = {...this.state.optin};
        newState.memberYes = e.target.value;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    memberNo = (value) => {
        let newState = {...this.state.optin};
        newState.memberNo = value;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    handleChange = (field, e) => {
        let newState = {...this.state.optin};
        newState[field] = e.target.value;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    handleAcceptConfirmAdult = () => {
        let newState = {...this.state.optin};
        newState.confirmAdult = !newState.confirmAdult;
        this.setState({
        ...this.state,
        optin: newState
        });
    }


    validateEmail = (email) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());
    }

    validateDob = (dob) => {
        const birthday = moment(dob);
        return birthday.isValid() && birthday.isBefore();
    }

    validateUsername = (username)=>{
        return /^[^@]+$/.test(String(username)) && !this.validateEmail(username);
    }

    toRegister = () => {
        this.setState({
            loginEnabled: false,
            registerEnabled: true,
            forgotEnabled: false
        });
    }

    toInitial = () => {

        this.setState({
            loggedIn: false,
            loginEnabled: false,
            registerEnabled: false,
            forgotEnabled: false
        });
    }

    toForgot = () => {

        this.setState({
            loginEnabled: false,
            registerEnabled: false,
            forgotEnabled: true
        });
    }

    toLogin = () => {

        this.setState({
            loginEnabled: true,
            registerEnabled: false,
            forgotEnabled: false
        });
    }

    handleToLoginFromReset = () => {

        this.setState({
            loginEnabled: true,
            registerEnabled: false,
            forgotEnabled: false,
            closeReset: true
        });
    }

    handleLoginFacebook = (response) => {

        let {userID, accessToken, picture, idCompetition} = response;
        let imageUrl = picture.data.url;

        this.props.dispatch(signInFace(
            {userID, accessToken, imageUrl, idCompetition},
            this.props.dispatch
        ));
    }

    handleTwitterLogin = (response) => {

        let {idCompetition} = response;
        this.props.dispatch(signInTwitter(
            {idCompetition},
            this.props.dispatch
        ));
    }

    loginRoute = () => {
        if (this.props.competition.data.type === "tipping") {
            this.handleLoginTipping();
        }
        else {
            this.handleLoginTipping();
        }
    }

    handleLogin = () => {

        const {comp} = this.props;
        const {username, password} = this.state.optin;
        const data = {username: `${username}#${comp.id}`, password};

        this.props.dispatch(signIn(
            data,
            this.props.dispatch
        ));
    }

    handleLoginTipping = () => {

        const {comp} = this.props;
        const {username, password} = this.state.optin;

        console.log('userna', username);
        console.log('userna', password);

        let passwordToSend = isSpecialComp(comp) ? getPassword(comp.id) : password;
        let usernameToSend = isSpecialComp(comp) || !this.validateEmail(username)
                                ? `${username}#${comp.id}` : username;
        const data = {username: usernameToSend, password: passwordToSend, idClientComp: comp.id};

        if (comp.type === "tipping") {
            data.isTipping = true;
            data.idComp = comp.id;
        }

        this.props.dispatch(signIn(
            data,
            this.props.dispatch
        ));
    }

    handleForgotStep2 = () => {

        const {recoverPass} = this.props.auth;
        const {forgotNewPass} = this.state;

        this.props.dispatch(reset({
                validationString: recoverPass,
                password: forgotNewPass
            },
            this.props.dispatch
        ));
    }

    handleForgot = () => {

        let {forgotEmail} = this.state;
        const {comp} = this.props;

        this.props.dispatch(forgot(
            {email: forgotEmail, idCompetition: comp.id},
            this.props.dispatch
        ));
    }

    renderLogins = () => {

        const {comp,t} = this.props;
        const {
            username, password
        } = this.state.optin;

        let loginButtonText =  t('login');

        if (this.props.auth) {
            loginButtonText = (this.props.auth.logginIn) ? 'Loading...' : t('login');
        }

        const errMessage = this.props.auth && this.props.auth.errMessage ? this.props.auth.errMessage : undefined;

        return (<div className='login-options popup login'>
            <div className='form'>
            <div className='login-content'>
                <h1><Trans i18nKey="login_for_access" /></h1>
                {/* <p><Trans i18nKey="login_leaderboard_picks" /></p> */}
            </div>
                <div className='optin-row'>
                    <div className='optin-col'>
                <FormControl
                    type="text"
                    value={username}
                    placeholder={!isSpecialComp(comp) ? t("e_mail_address") : t("username")}
                    onChange={(e) => this.handleChange('username', e)}
                />
                    </div>
                </div>
            {!isSpecialComp(comp) ? (
                <div className='optin-row'>
                    <div className='optin-col'>
                <FormControl
                    type="password"
                    value={password}
                    placeholder={t("password")}
                    onChange={(e) => this.handleChange('password', e)}
                />                        
                    </div>
                </div>
            ) : null}
            </div>
            {errMessage ? (
                <div className='login-error'>{errMessage}</div>
            ) : null}
                <div className='optin-row'>
                    <div className='optin-col'>
            <div
                // style={{backgroundColor: comp ? comp.buttonColor : '', color: comp ? comp.secondaryColor : ''}}
                style={{backgroundColor: '#fff', color : '#2c2f30'}}
                className='button full-size' onClick={this.loginRoute}>
                 {loginButtonText}
            </div>
            {/* {this.renderSocialLogins()} */}
            {!isSpecialComp(comp) ? (
            <div 
                //style={{backgroundColor: comp ? comp.buttonColor : '', color: comp ? comp.secondaryColor : ''}}
                style={{backgroundColor: '#fff', color : '#2c2f30'}}
                className='button full-size' onClick={this.toForgot}>
                <Trans i18nKey="forgot_password" />
            </div>
            ) : null}
            </div></div>
            <div className='close' onClick={this.toInitial}></div>
        </div>)
    }

    renderResetStep2 = () => {

        const {t} = this.props;
        let {recovering, recovered, recoverSuccess, recoverMessage} = this.props.auth;

        return (<div className='login-options popup'>
            <div className='form'>
            <div className='optin-row'>
                    <div className='optin-col'>
                <FormControl
                    type="password"
                    value={this.state.forgotNewPass}
                    placeholder={t('new_password')}
                    onChange={(e) => this.handleChangeNonOptin('forgotNewPass', e)}
                />
            </div></div></div>
            {recoverMessage ? (
                <div className='login-error'>{recoverMessage}</div>
            ) : null}
            <div className='optin-row'>
                    <div className='optin-col'>
            <div className='button full-size' onClick={this.handleForgotStep2}
                style={{backgroundColor: '#fff', color : '#2c2f30'}}>
            <Trans i18nKey="store_new_password" /><em>></em>
            </div>
            <div className='button full-size' onClick={this.handleToLoginFromReset}
                style={{backgroundColor: '#fff', color : '#2c2f30'}}>
            <Trans i18nKey="go_to_login" /><em>></em>
            </div>
            </div></div>
        </div>)
    }

    renderForgot = () => {

        const {forgotEmail} = this.state;
        const {comp,t} = this.props;

        let {recovering, recovered, recoverSuccess, recoverMessage, recoverCode} = this.props.auth;
      
        return (<div className='login-options popup'>
            <div className='form'>
            <div className='login-content'>
                <h1><Trans i18nKey="forgot_password" /></h1>
            </div>
            <div className='optin-row'>
                    <div className='optin-col'>
                <FormControl
                    type="text"
                    value={forgotEmail}
                    placeholder={t('e-mail')}
                    onChange={(e) => this.handleChangeNonOptin('forgotEmail', e)}
                />
            </div></div></div>
            {recoverCode && recoverCode != 0 ? 
                (<div className='login-error'><Trans i18nKey="email_address_err" /></div>) :
            recoverCode && recoverCode == 0 ? (
                <div className='login-error'><Trans i18nKey="email_has_sent" /></div>
            ) : null}
            <div className='optin-row'>
                    <div className='optin-col'>
            <div style={{backgroundColor: '#fff', color : '#2c2f30'}}
                 className='button full-size' onClick={this.handleForgot}>
               {forgotEmail?(recovering?<Trans i18nKey="sending" />:((recovered && recoverSuccess)?<Trans i18nKey="done" />:<Trans i18nKey="recover" />)):<Trans i18nKey="recover" />}
            </div>
            </div></div>
            <div className='close' onClick={this.toInitial}></div>
        </div>)
    }

    renderSocialLogins = () => {
        const {comp} = this.props;
        const faceLogin = <FacebookLogin
            appId={comp.facebookAppId}
            redirectUri={comp.webapp}
            autoLoad={false}
            callback={(e) => this.handleLoginFacebook({
                ...e,
                idCompetition: comp.id
            })}
            disableMobileRedirect={true}
            fields="name,email,picture"
            scope="public_profile,email"
            render={renderProps => (
                <div className='button full-size facebook' onClick={renderProps.onClick}>
                    <Trans i18nKey="facebook_login" />
                    <FontAwesomeIcon
                        icon={faFacebookF}
                        className={`submit-arr`}
                    />
                </div>
            )}
        />;
        const twitterLogin = <div className='button full-size twitter'
                                  onClick={(e) => this.handleTwitterLogin({
                                      ...e,
                                      idCompetition: comp.id
                                  })}>
           <Trans i18nKey="twitter_login" />
            <FontAwesomeIcon icon={faTwitter}
                             className={`submit-arr`}
            />
        </div>;
        return (<div>
            { !isSpecialComp(comp) && comp.isFacebookLoginEnabled ? faceLogin : null}
            { !isSpecialComp(comp) && comp.isTwitterLoginEnabled ? twitterLogin : null}
        </div>);
    }

    sendParentScrollMessage = (e) => {
        var domRect = e.target.getBoundingClientRect();
        if (window.self !== window.top) {
            window.parent.postMessage(`benchvoteScroll-${domRect.top}`,
                'http://www.westernbulldogs.com.au');
        }
    }

    handleSelectOptinOtherOptionSingle = (idOptinOther, ooo, e) => {
        const optin = {
            ...this.state.optin
        };
        if (!optin.others[idOptinOther]) {
            optin.others[idOptinOther] = {};
            optin.others[idOptinOther].selectedValues = [];
        }
        optin.others[idOptinOther].selectedValues = [ooo.id];
        this.setState({optin});
    }

    handleSelectOptinOtherOption = (idOptinOther, ooo, e) => {
        const optin = {
            ...this.state.optin
        };
        if (!optin.others[idOptinOther]) {
            optin.others[idOptinOther] = {};
            optin.others[idOptinOther].selectedValues = [];
        }
        if (e.target.checked) {
            optin.others[idOptinOther].selectedValues.push(ooo.id);
        } else {
            optin.others[idOptinOther].selectedValues = optin.others[idOptinOther]
                .selectedValues.filter(o2 => o2 !== ooo.id);
        }
        this.setState({optin});
    }

    handleChangeOptinOther = (idOptinOther, e) => {
        const optin = {
            ...this.state.optin
        };
        if (!optin.others[idOptinOther]) {
            optin.others[idOptinOther] = {};
        }
        optin.others[idOptinOther].value = e.target.value;
        this.setState({optin});
    }


    handleChangeOptinOtherComm = (idOptinOther, e) => {
        const {t} = this.props;
        const optin = {
            ...this.state.optin
        };
        if (!optin.others[idOptinOther]) {
            optin.others[idOptinOther] = {};
        }
        optin.others[idOptinOther].value = e.target.value;
        this.setState({optin});

        var maxLength = 2000;
        if (e.target.value.length > maxLength) {
        this.state.charLengthCustomComm[idOptinOther] =
            `${t('character_left')}: ` + e.target.value.substring(0, maxLength) + '/2000';
        } else {
        this.state.charLengthCustomComm[idOptinOther] =
        `${t('character_left')}: ` + (maxLength - e.target.value.length) + '/2000';
        }
    }

    handleChangeOptinOtherDate = (idOptinOther, date) => {
        const optin = {
            ...this.state.optin
        };
        if (!optin.others[idOptinOther]) {
            optin.others[idOptinOther] = {};
        }
        optin.others[idOptinOther].value = date;
        this.setState({optin});
    }

    handleMobileNumber = (event)=>{
        let newState = {...this.state.optin};
        newState['mobile'] = event;
        this.setState({
            ...this.state,
            optin: newState
        });
    }

    renderCustomFieldsActual = (oo, others, otherIndex, t) => {
        switch (oo.type) {
            case 'text':
                return <div className="optin-row">
                    <div className='optin-col'>
                        <FormControl
                            type="text"
                            value={(others[oo.id] && others[oo.id].value) || ''}
                            placeholder={oo.copy}
                            onFocus={this.sendParentScrollMessage}
                            onChange={(e) => this.handleChangeOptinOther(oo.id, e)}
                        />
                    </div>
                </div>;
            case 'comment':
                return <div className="optin-row">
                    <div className='optin-col'>
                        <FormControl
                            rows="4"
                            componentClass="textarea"
                            value={(others[oo.id] && others[oo.id].value) || ''}
                            placeholder={oo.copy}
                            onFocus={this.sendParentScrollMessage}
                            onChange={(e) => this.handleChangeOptinOtherComm(oo.id, e)}
                            className='textarea'
                            maxLength='2000'
                        />
                        <div style={{ marginTop: 5, textAlign: "center", color: "#FFFFFF" }}>{this.state.charLengthCustomComm[oo.id]
                            ? this.state.charLengthCustomComm[oo.id] : this.state.charLengthCustom}</div>
                    </div>
                </div>;
            case 'date':
                return <div className="optin-row">
                    <div className='optin-col'>
                        <div class="text-light">{oo.copy}</div>
                        <DatePicker
                            selected={(others[oo.id] && others[oo.id].value) || new Date()}
                            onFocus={this.sendParentScrollMessage}
                            onChange={(date) => this.handleChangeOptinOtherDate(oo.id, date)}
                        />
                    </div>
                </div>;
            case 'checkbox':
                return <div className="optin-row"><div className='optin-col'>
                    <div class="text-light">{oo.copy}</div>
                    {oo.options.filter(o => !o.deleted).map((ooo, index) =>
                        <Checkbox id={`checkboxGroup${otherIndex}${index}`} className="custom-control custom-checkbox custom-control-inline"
                            onClick={(e) => this.handleSelectOptinOtherOption(oo.id, ooo, e)} inline
                        >
                            <label class="custom-control-label" for={`checkboxGroup${otherIndex}${index}`} dangerouslySetInnerHTML={{ __html: ooo.copy }} />
                        </Checkbox>)}
                </div></div>;
            case 'dropdown':
                return <div className="optin-row"><div className='optin-col'>
                    <div class="text-light">{oo.copy}</div>
                    <DropdownButton title={(others[oo.id] && others[oo.id].selectedValues) ?
                        oo.options.filter(o => !o.deleted).find(ooo => others[oo.id].selectedValues[0] === ooo.id).copy : t('select_an_option')}>
                        {oo.options.filter(o => !o.deleted).map(ooo =>
                            <MenuItem
                                onClick={(e) => this.handleSelectOptinOtherOptionSingle(oo.id, ooo, e)}
                            >
                                <span dangerouslySetInnerHTML={{ __html: ooo.copy }} />
                            </MenuItem>)}
                    </DropdownButton>
                </div></div>;
            case 'radio':
                return <div className="optin-row"><div className='optin-col'>
                    <div class="text-light">{oo.copy}</div>
                    {oo.options.filter(o => !o.deleted).map((ooo, index) =>
                        <Radio id={`radioGroup${otherIndex}${index}`} className="custom-control custom-radio custom-control-inline"
                            name={`radioGroup${oo.id}`}
                            inline
                            onClick={(e) => this.handleSelectOptinOtherOptionSingle(oo.id, ooo, e)}
                        >
                            <label class="custom-control-label" for={`radioGroup${otherIndex}${index}`} dangerouslySetInnerHTML={{ __html: ooo.copy }} />
                        </Radio>)}
                </div></div>;
        }
    }

    renderRegistration = () => {
        const {
            username, firstname, lastname, email,
            mobile, address, state,
            postcode, tnc, marketing,
            other, member, memberNo,
            memberYes, password, tncs, others,
            dob, confirmAdult
        } = this.state.optin;
        const {comp,t} = this.props;
        const errors = this.state.errStates;
        let registerButtonText =  t('register');

        if (this.props.auth) {
            registerButtonText = (this.props.auth.registering) ? t('create_user') : t('register');
        }

        const showUsername = (comp && (comp.type === "tipping" || SHOW_USERNAME_NON_TIPPING.includes(comp.id)));
        

        let compSpecificFields = {
            'leaderboard': [
                {
                    id: 'optinPassword',
                    value: password,
                    placeholder: 'password',
                    insertIndex: 1,
                    toIgnore: true,
                    model: 'password',
                    type: 'password',
                    error: {
                        field: 'password',
                        message: 'password_validation'
                    }
                }
            ],
            'video-lb': [
                {
                    id: 'optinPassword',
                    value: password,
                    placeholder: 'password',
                    insertIndex: 1,
                    toIgnore: true,
                    model: 'password',
                    type: 'password',
                    error: {
                        field: 'password',
                        message: 'password_validation'
                    }
                }
            ],
            'tipping': [
                {
                    id: 'optinPassword',
                    value: password,
                    placeholder: 'password',
                    insertIndex: 1,
                    toIgnore: true,
                    model: 'password',
                    type: 'password',
                    error: {
                        field: 'password',
                        message: 'password_validation'
                    }
                }
            ],
            'omnihub': [
                {
                    id: 'optinPassword',
                    value: password,
                    placeholder: 'password',
                    insertIndex: 1,
                    toIgnore: true,
                    model: 'password',
                    type: 'password',
                    error: {
                        field: 'password',
                        message: 'password_validation'
                    }
                }
            ],
        };

        if (showUsername) {
            compSpecificFields[comp.type].unshift({
                    id: 'username',
                    value: username,
                    model: 'username',
                    insertIndex: 0,
                    toIgnore: true,
                    placeholder: 'username',
                    type: 'text',
                    error: {
                        field: 'firstname',
                        message: 'err_first_name'
                    }
                });
        }

        let leadCaptureFields = [
            {
                id: 'optinFirstname',
                value: firstname,
                model: 'firstname',
                placeholder: 'first_name',
                type: 'text',
                error: {
                    field: 'firstname',
                    message: 'err_first_name'
                }
            },
            {
                id: 'optinLastname',
                value: lastname,
                model: 'lastname',
                placeholder: 'last_name',
                type: 'text',
                error: {
                    field: 'lastname',
                    message: 'err_last_name'
                }
            },
            {
                id: 'optinEmail',
                value: email,
                model: 'email',
                placeholder: 'email_address',
                type: 'text',
                error: {
                    field: 'email',
                    message: 'email_addres_err'
                }
            },
            {
                id: 'optinPhone',
                value: mobile,
                model: 'phone',
                type: 'text',
                error: {
                    field: 'mobile',
                    message: 'mobile_number_err'
                }
            },
            {
                id: 'optinDob',
                value: dob,
                model: 'dob',
                placeholder: 'dob',
                type: 'date',
                error: {
                    field: 'dob',
                    message: 'dob_err'
                }
            },
            {
                id: 'optinAddress',
                value: address,
                model: 'address',
                placeholder: 'address',
                type: 'text',
                error: {
                    field: 'address',
                    message: 'address_err'
                }
            },
            {
                id: 'optinState',
                value: state,
                model: 'state',
                placeholder: 'state',
                type: 'text',
                error: {
                    field: 'state',
                    message: 'state_err'
                }
            },
            {
                id: 'optinPostcode',
                value: postcode,
                model: 'postcode',
                placeholder: 'postcode',
                type: 'text',
                error: {
                    field: 'postcode',
                    message: 'postcode_err'
                }
            },
        ];


        let otherLCFields = [
            {
                id: 'optinPassword',
                value: password,
                placeholder: 'password',
                model: 'password',
                type: 'password',
                error: {
                    field: 'password',
                    message: 'password_validation'
                }
            },
            {
                id: 'optinMember',
                value: member,
                placeholder: 'comments',
                model: 'member',
                type: 'text',
            },
            {
                id: 'optinTncs',
                value: tnc,
                model: 'tnc',
                placeholder: 'confirm_adult',
                type: 'text',
                error: {
                    field: 'tncError',
                    message: 'terms_condition_18'
                }
            },
            {
                id: 'optinMarketing',
                value: marketing,
                model: 'optin',
                placeholder: 'confirm_adult',
                type: 'text'
            },
            {
                id: 'optinConfirmAdult',
                value: confirmAdult,
                model: 'confirmAdult',
                placeholder: 'confirm_adult',
                type: 'text',
                error: {
                    field: 'confirmAdultError',
                    message: 'confirm_adult_err'
                }
            },
        ];

        console.log('show-user-name', showUsername);

        if (comp.isLCFOrderingEnabled) {
            leadCaptureFields = leadCaptureFields.concat(otherLCFields);
        }

        if (comp.otherOptins && comp.otherOptins.length > 0) {
            comp.otherOptins.forEach(function (oo) {
                if (oo.disabled || oo.deleted) {
                    return;
                }
                oo.isCustom = true;
                oo.model = 'custom';
                // if (oo.index != null) {
                //   console.log('index', oo);
                //   that.leadCaptureFields[oo.index - 1] = oo;
                // } else {
                console.log('noindex', oo)
                leadCaptureFields.push(oo);
                // }
            });
        }

        if (!comp.isLCFOrderingEnabled) {
            leadCaptureFields = leadCaptureFields.concat(otherLCFields);
            if (compSpecificFields[comp.type]) {
                for (var csfKey in compSpecificFields[comp.type]) {
                    if ((!showUsername || csfKey != 'username')
                        && compSpecificFields[comp.type][csfKey]) {
                        let insertIndex = compSpecificFields[comp.type][csfKey]['insertIndex'];
                        leadCaptureFields.splice(insertIndex, 0, compSpecificFields[comp.type][csfKey]);
                    }
                }
            }
        }

        // leadCaptureFields.forEach((field, fieldIndex) => {
        //     if (!field.isCustom && !field.toIgnore) {
        //         if (!comp[field.id] || comp[field.id] == 0 || comp[field.id] == null) {
        //             leadCaptureFields.splice(fieldIndex, 1);
        //         }
        //     }
        // })

        for (let i = leadCaptureFields.length - 1; i >= 0; i -= 1) {
            let field = leadCaptureFields[i];
            if (!field.isCustom && !field.toIgnore) {
                if (!comp[field.id] || comp[field.id] == 0 || comp[field.id] == null) {
                    leadCaptureFields.splice(i, 1);
                }
            }
        }

        console.log('lead-1', leadCaptureFields);

        let leadCaptureFieldsHolder = [];
        let leadCaptureFieldsSorted = [];

        let fieldsOrderingListData = {};
        if (comp.fieldsOrdering && comp.fieldsOrdering != '') {
            var fieldsOrderingList = comp.fieldsOrdering.split(',');
            if (fieldsOrderingList && fieldsOrderingList.length > 0) {
                fieldsOrderingList.forEach((fol, folIndex) => {
                    var fieldsSplit = fol.split('-');
                    console.log("fields-split", fieldsSplit);
                    if (fieldsSplit && fieldsSplit.length == 2
                        && (showUsername || fieldsSplit[0] != 'username')) {
                        fieldsOrderingListData[fieldsSplit[0]] = fieldsSplit[1];
                    }
                });
            }
        }
        console.log('fields-order', fieldsOrderingListData);
        console.log('lead-2', leadCaptureFields);

        if (comp.isLCFOrderingEnabled) {
            for (var folIndex in fieldsOrderingListData) {
                if (fieldsOrderingListData[folIndex] && compSpecificFields[comp.type]) {

                    let matchingLcfField = compSpecificFields[comp.type].find((csf) => {
                        return csf.id == folIndex;
                    });
                    if (matchingLcfField) {
                        matchingLcfField.insertIndex = fieldsOrderingListData[folIndex];
                        leadCaptureFields.push(matchingLcfField);
                    }
                }
            }

            console.log('lead-3', leadCaptureFields);

            leadCaptureFields.forEach((field, fieldIndex) => {
                if (!field.isCustom && !field.toIgnore) {
                    if (comp[field.id] && comp[field.id] > 0 && comp[field.id] != null) {
                        if (!field.restrictedTypes || !field.restrictedTypes.includes(comp.type)) {
                            leadCaptureFieldsSorted.push([field.id, comp[field.id]]);
                        }
                    }
                } else if (field.isCustom) {
                    if (!field.disabled) {
                        leadCaptureFieldsSorted.push([field.id, field.index]);
                    }
                } else if (field.toIgnore && fieldsOrderingListData[field.id] >= 0) {
                    leadCaptureFieldsSorted.push([field.id, fieldsOrderingListData[field.id]])
                }
            });

            console.log('lead-sort', leadCaptureFieldsSorted);


            leadCaptureFieldsSorted.sort(function (a, b) {
                return a[1] - b[1];
            });

            leadCaptureFieldsSorted.forEach((field, fieldIndex) => {
                var searchField = leadCaptureFields.find((lcf) => {
                    return lcf && lcf.id === field[0];
                });
                if (searchField) {
                    leadCaptureFieldsHolder.push(searchField);
                }
            });

            leadCaptureFields = leadCaptureFieldsHolder;
        }

        if (isSpecialComp(comp)) {
            // leadCaptureFields.forEach((field, fieldIndex) => {
            //     if (field.id == 'optinEmail' || field.id == 'optinPassword') {
            //         leadCaptureFields.splice(fieldIndex, 1);
            //     }
            // });

            for (let i = leadCaptureFields.length - 1; i >= 0; i -= 1) {
                let field = leadCaptureFields[i];
                if (field.id == 'optinEmail' || field.id == 'optinPassword') {
                    leadCaptureFields.splice(i, 1);
                }
            }
        }

        return (<div className='login-options popup register'>
            <div className='login-content'>
                <h1><Trans i18nKey="register_for_access" /></h1>
                {/* <p><Trans i18nKey="register_leaderboard_picks" /></p> */}
            </div>
            {leadCaptureFields
                .filter(o => !o.disabled && !o.deleted)
                .map((oo, otherIndex) => {
                    switch (oo.model) {
                        case "username":
                            return (
                                <div className='optin-row'>
                                    <div className='optin-col first'>
                                        <FormControl
                                            type="text"
                                            value={username}
                                            placeholder={t('username')}
                                            onFocus={this.sendParentScrollMessage}
                                            onChange={(e) => this.handleChange('username', e)}
                                        />
                                        {errors.username ? (
                                            <HelpBlock><Trans i18nKey="enter_user_name" /></HelpBlock>
                                        ) : null}
                                        {errors.invalidUsername ? (
                                            <HelpBlock><Trans i18nKey="invalid_user_name" /></HelpBlock>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        case "phone":
                            return (
                                <div className='optin-row'>
                                    <div className='optin-col'>
                                        {/* <FormControl
                            type="text"
                            value={mobile}
                            placeholder={t('mobile_number')}
                            onFocus={this.sendParentScrollMessage}
                            onChange={(e) => this.handleChange('mobile', e)}
                        /> */}
                                        <IntlTelInput
                                            containerClassName="intl-tel-input"
                                            inputClassName="form-control"
                                            value={mobile.value}
                                            preferredCountries={this.preferredCountries}
                                            defaultCountry={(comp.phoneDefaultCountry && comp.phoneDefaultCountry != null)
                                                            ? comp.phoneDefaultCountry : 'au'}
                                            placeholder={t('mobile_number')}
                                            separateDialCode={true}
                                            onPhoneNumberChange={(status, value, countryData, number) => this.handleMobileNumber({ status, value, countryData, number })}
                                            onSelectFlag={(value, countryData, number, status) => this.handleMobileNumber({ value, countryData, number, status })}
                                        />
                                        {errors.mobile ? (
                                            <HelpBlock><Trans i18nKey="mobile_number_err" /></HelpBlock>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        case "confirmAdult":
                            return (
                                <div className='optin-row'>
                                    <div className='optin-col'>
                                        <Checkbox id="optinConfirmAdult" className="custom-control custom-checkbox custom-control-inline"
                                            checked={confirmAdult}
                                            onClick={this.handleAcceptConfirmAdult}
                                        >
                                            <label class="custom-control-label"
                                                for="optinConfirmAdult"><Trans i18nKey="confirm_adult" /></label>
                                        </Checkbox>
                                        {errors.confirmAdult ? (
                                            <HelpBlock><Trans i18nKey="confirm_adult_err" /></HelpBlock>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        case "optin":
                            return (
                                <div className='optin-row'>
                                    <div className='optin-col'>
                                        <Checkbox id="optinMarketingCopy" className="custom-control custom-checkbox custom-control-inline"
                                            checked={marketing}
                                            onClick={this.handleAcceptMarketing}
                                        >
                                            <label class="custom-control-label" for="optinMarketingCopy" dangerouslySetInnerHTML={{ __html: comp.optinMarketingCopy }}></label>
                                        </Checkbox>
                                    </div>
                                </div>
                            )
                        case "tnc":
                            return (
                                <div className='optin-row'>
                                    <div className='optin-col'>
                                        <Checkbox id="optinTncsCopy" className="custom-control custom-checkbox custom-control-inline"
                                            checked={tnc}
                                            onClick={this.handleAcceptTnc}
                                        >
                                            <label class="custom-control-label" for="optinTncsCopy" dangerouslySetInnerHTML={{ __html: comp.optinTncsCopy }}></label>
                                        </Checkbox>
                                        {errors.tnc ? (
                                            <HelpBlock><Trans i18nKey="accept_term_condition" /></HelpBlock>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        case "dob":
                            return (
                                <div className='optin-row'>
                                    <div className='text-center' style={{ color: '#FFFFFF' }}>
                                        <Trans i18nKey="dob" />
                                    </div>
                                    <div className='optin-col'>
                                        <FormControl
                                            className='date_of_birth'
                                            type='date'
                                            placeholder={t(oo.placeholder)}
                                            name='dob'
                                            onFocus={this.sendParentScrollMessage}
                                            onChange={(e) => this.handleChange('dob', e)}
                                            value={dob}
                                        />
                                        {errors.dob ? (
                                            <HelpBlock><Trans i18nKey="dob_err" /></HelpBlock>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        case "member":
                            return (
                                <div>
                                    {comp.optinMember ? (
                                        <div className='optin-row membership-flow'>
                                            <div className='optin-col'>
                                                <div className='text-light'>{comp.optinMemberLabel}</div>
                                                <FormGroup>
                                                    <Radio id={`optinMemberYes`} className="custom-control custom-radio custom-control-inline" name="radioGroup" onClick={() => this.selectMembership(true)} inline>
                                                        <label class="custom-control-label" for="optinMemberYes"><Trans i18nKey="yes" /></label>
                                                    </Radio>{' '}
                                                    <Radio id={`optinMemberNo`} className="custom-control custom-radio custom-control-inline" name="radioGroup" onClick={() => this.selectMembership(false)} inline>
                                                        <label class="custom-control-label" for="optinMemberNo"><Trans i18nKey="no" /></label>
                                                    </Radio>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    ) : null}
                                    {comp.optinMember && member === true ? (
                                        <div className='optin-row'>
                                            <div className='optin-col last'>
                                                <FormControl
                                                    type="text"
                                                    value={memberNo}
                                                    placeholder={comp.optinMemberYesCopy}
                                                    onFocus={this.sendParentScrollMessage}
                                                    onChange={this.memberYes}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {comp.optinMember && member === false ? (
                                        <div className='optin-row membership-flow'>
                                            <div className='optin-col'>
                                                <div className='text-light'>{comp.optinMemberNoCopy}</div>
                                                <FormGroup>
                                                    <Radio id={`MemberNoGroupYes`} className="custom-control custom-radio custom-control-inline" name="MemberNoGroup" onClick={() => this.memberNo(true)} inline>
                                                        <label class="custom-control-label" for="MemberNoGroupYes"><Trans i18nKey="yes" /></label>
                                                    </Radio>{' '}
                                                    <Radio id={`MemberNoGroupNo`} className="custom-control custom-radio custom-control-inline" name="MemberNoGroup" onClick={() => this.memberNo(false)} inline>
                                                        <label class="custom-control-label" for="MemberNoGroupNo"><Trans i18nKey="no" /></label>
                                                    </Radio>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        case "custom":
                            return (
                                <div className="optin-row">
                                    {this.renderCustomFieldsActual(oo, others, otherIndex, t)}
                                    {errors.customMandatoryOptin && oo.hasError ? (
                                        <div className='optin-col'>
                                            <HelpBlock><Trans i18nKey="field_required" /></HelpBlock>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        default:
                            return (
                                <div className="optin-row">
                                    <div className="optin-col">
                                        <FormControl
                                            type={oo.type}
                                            value={oo.value}
                                            placeholder={t(oo.placeholder)}
                                            onFocus={this.sendParentScrollMessage}
                                            onChange={e => this.handleChange(oo.model, e)}
                                        />
                                        {errors[oo.error.field] ? (
                                            <HelpBlock>
                                                <Trans i18nKey={oo.error.message} />
                                            </HelpBlock>
                                        ) : null}
                                    </div>
                                </div>
                            )
                    }
                })}
            <div className='optin-row'>
                <div className='optin-col'>
            {this.props.auth.registrationError ? (
                this.props.competition.data.type === "tipping" && this.props.auth.registrationErrorCode == 'USER09' ? (
                    <div className='rego-error'>Username is already taken!</div>
                ) : <div className='rego-success'>{this.props.auth.registrationMessage}</div>
            ) : null}
            {this.props.auth.registrationSuccess ? (
                <div className='rego-success'>{this.props.auth.registrationMessage}</div>
            ) : null}
            </div></div>
            <div className='optin-row'>
                <div className='optin-col'>
            <div
                style={{backgroundColor: /*comp.buttonColor*/'#00b909', color: comp.primaryColor}}
                className='button btn-block full-size white_btn'
                onClick={this.registerRoute}
            >
                {registerButtonText}
            </div>
            </div>
            </div>
            {/* {this.renderSocialLogins()} */}
            <div className='close' onClick={this.toInitial}> </div>
            <p className='text-uppercase already'><Trans i18nKey="already_have_account" />?<br/><strong  onClick={this.toLogin}><Trans i18nKey="login_in_here" /></strong></p>
        </div>)
    }

    registerRoute = () => {
        if (this.props.competition.data.type === "tipping"
            || SHOW_USERNAME_NON_TIPPING.includes(this.props.competition.data.id)) {
            this.handleRegisterTipping();
        }
        else {
            this.handleRegister();
        }
    }

    handleRegister = () => {

        const {
            firstname, lastname, email,
            mobile, address, state,
            postcode, other, password,
            tnc, marketing, member,
            memberYes, memberNo, others,
            dob, confirmAdult
        } = this.state.optin;

        const {comp} = this.props;
        const {
            optinLastname, optinPhone, optinAddress,
            optinPostcode, optinState, optinOther,
            optinDob, optinConfirmAdult
        } = comp;

        const uppercaseRegex = new RegExp("(?=.*[A-Z])");
        const numberRegex = new RegExp("(?=.*[0-9])");
        let es = {
            firstname: !firstname,
            lastname: optinLastname && !lastname,
            email: !this.validateEmail(email),
            mobile: optinPhone && (!mobile || isNaN(mobile.value) || !mobile.status),
            address: optinAddress && !address,
            state: optinState && !state,
            postcode: optinPostcode && !postcode || isNaN(postcode),
            other: optinOther && !other,
            tnc: !tnc,
            password: !password || password.trim().length < 8 || !uppercaseRegex.test(password.trim()) || !numberRegex.test(password.trim()),
            dob: optinDob && !this.validateDob(dob),
            confirmAdult: optinConfirmAdult && !confirmAdult,
            customMandatoryOptin: false
        }

        const otherOptins = comp.otherOptins;

        if (otherOptins && otherOptins.length > 0) {
            otherOptins.forEach((oo, key) => {
                if (otherOptins.hasOwnProperty(key) && !otherOptins[key].disabled
                    && !otherOptins[key].deleted && otherOptins[key].mandatory) {
                    switch (otherOptins[key].type) {
                        case 'text':
                        case 'date':
                        case 'comment':
                            if (!others[otherOptins[key].id] || !others[otherOptins[key].id].hasOwnProperty('value')
                                || others[otherOptins[key].id].value == null || !others[otherOptins[key].id].value
                                || others[otherOptins[key].id].value.trim() == "") {
                                es.customMandatoryOptin = true;
                                otherOptins[key].hasError = true;
                            } else {
                                otherOptins[key].hasError = false;
                            }
                            break;
                        case 'checkbox':
                        case 'dropdown':
                        case 'radio':
                            if (!others[otherOptins[key].id] || !others[otherOptins[key].id].hasOwnProperty('selectedValues')
                                || others[otherOptins[key].id].selectedValues.length < 1 || others[otherOptins[key].id].selectedValues[0] == "") {
                                es.customMandatoryOptin = true;
                                otherOptins[key].hasError = true;
                            } else {
                                otherOptins[key].hasError = false;
                            }
                            break;
                    }
                }
            });
        }


        const otherData = comp.otherOptins
            .filter(o => !o.disabled && !o.deleted).map(oo => {
                if (!oo.deleted) {
                    if (oo.type === 'text' || oo.type === 'comment') {
                        return {
                            id: oo.id,
                            type: oo.type,
                            value: others[oo.id] ? others[oo.id].value : ''
                        };
                    } else if (oo.type === 'date') {
                        return {
                            id: oo.id,
                            type: oo.type,
                            value: others[oo.id] ? others[oo.id].value : new Date()
                        };
                    } else if (oo.type === 'checkbox' || oo.type === 'dropdown' || oo.type === 'radio') {
                        return {
                            id: oo.id,
                            type: oo.type,
                            values: others[oo.id] ? others[oo.id].selectedValues : []
                        };
                    }
                }
            });

        
        let customFields = {};

        if (comp && comp.otherOptins && comp.otherOptins.length) {

            comp.otherOptins.forEach((oo, key) => {
                if (!oo.disabled && !oo.deleted) {
                    if ((oo.type === 'text' || oo.type === 'comment') && others && others[oo.id]
                        && others[oo.id].hasOwnProperty('value')) {
                        customFields[oo.copy] = others[oo.id].value;
                    } else if (oo.type === 'checkbox') {
                        if (oo && oo.options && oo.options.length) {
                            let newVal = [];
                            oo.options.forEach((ooo, okey) => {
                                if (others && others[oo.id] && others[oo.id].selectedValues
                                    && others[oo.id].selectedValues.includes(ooo.id)) {
                                    newVal.push(ooo.copy);
                                }
                            });
                            if (newVal.length > 0) {
                                customFields[oo.copy] = newVal.join(',');
                            }
                        }
                    } else if (oo.type === 'dropdown' || oo.type === 'radio') {
                        if (oo && oo.options && oo.options.length) {
                            oo.options.forEach((ooo, okey) => {
                                if (others && others[oo.id]
                                    && others[oo.id].selectedValues
                                    && others[oo.id].selectedValues.includes(ooo.id)) {
                                    customFields[oo.copy] = ooo.copy;
                                }
                            });
                        }
                    }
                }
            });
        }

        if (es.firstname || es.lastname || es.email || es.mobile || es.address
            || es.state || es.postcode || es.tnc || es.password || es.other
            || es.dob || es.confirmAdult || es.customMandatoryOptin) {

            this.setState({
                ...this.state,
                errStates: es
            });
            return;

        } else {

            this.props.dispatch(signUp(
                {
                    firstname, lastname, email,
                    mobile:mobile.value?(mobile.number).replace(/\s+/g, ''):'', address, state,
                    postcode, tnc, optin: marketing,
                    other, password, member,
                    memberYes, memberNo,
                    dob, confirmAdult,
                    idCompetition: comp.id, otherData
                },
                this.props.dispatch
            ));

            if (comp && comp.webhooks && comp.webhooks.find(wh => wh.type === 'register')) {
                console.log("Register webhook enabled");
                this.props.dispatch(webhookRegister(
                    {
                        firstname, lastname, email,
                        mobile:mobile.value?(mobile.number).replace(/\s+/g, ''):'', address, state,
                        postcode, tnc, optin: marketing,
                        other, password, member,
                        memberYes, memberNo,
                        dob, confirmAdult, customFields,
                        idCompetition: comp.id, otherData
                    },
                    this.props.dispatch
                ));
            }

            this.setState({
                ...this.state,
                errStates: es
            });
        }
    }

    handleRegisterTipping = () => {

        const {
            username, firstname, lastname, email,
            mobile, address, state,
            postcode, other, password,
            tnc, marketing, member,
            dob, confirmAdult,
            memberYes, memberNo, others
        } = this.state.optin;

        const {comp} = this.props;
        const {
            optinLastname, optinPhone, optinAddress,
            optinPostcode, optinState, optinOther,
            optinDob, optinConfirmAdult
        } = comp;

        const uppercaseRegex = new RegExp("(?=.*[A-Z])");
        const numberRegex = new RegExp("(?=.*[0-9])");
        let passwordToSend = isSpecialComp(comp) ? getPassword(comp.id) : password;
        let emailToSend = isSpecialComp(comp) ? getEmail(username, comp.id) : email;
        let es = {
            username: !username ,
            invalidUsername: username && !this.validateUsername(username),
            firstname: !firstname,
            lastname: optinLastname && !lastname,
            email: !this.validateEmail(emailToSend),
            mobile: optinPhone && (!mobile || !mobile.status),
            address: optinAddress && !address,
            state: optinState && !state,
            postcode: optinPostcode && !postcode || isNaN(postcode),
            other: optinOther && !other,
            tnc: !tnc,
            password: !passwordToSend || passwordToSend.trim().length < 8
                || !uppercaseRegex.test(passwordToSend.trim()) || !numberRegex.test(passwordToSend.trim()),
            dob: optinDob && !this.validateDob(dob),
            confirmAdult: optinConfirmAdult && !confirmAdult,
            customMandatoryOptin: false
        }

        const otherOptins = comp.otherOptins;

        if (otherOptins && otherOptins.length > 0) {
            otherOptins.forEach((oo, key) => {
                if (otherOptins.hasOwnProperty(key) && !otherOptins[key].disabled
                    && !otherOptins[key].deleted && otherOptins[key].mandatory) {
                    switch (otherOptins[key].type) {
                        case 'text':
                        case 'date':
                        case 'comment':
                            if (!others[otherOptins[key].id] || !others[otherOptins[key].id].hasOwnProperty('value')
                                || others[otherOptins[key].id].value == null || !others[otherOptins[key].id].value
                                || others[otherOptins[key].id].value.trim() == "") {
                                es.customMandatoryOptin = true;
                                otherOptins[key].hasError = true;
                            } else {
                                otherOptins[key].hasError = false;
                            }
                            break;
                        case 'checkbox':
                        case 'dropdown':
                        case 'radio':
                            if (!others[otherOptins[key].id] || !others[otherOptins[key].id].hasOwnProperty('selectedValues')
                                || others[otherOptins[key].id].selectedValues.length < 1 || others[otherOptins[key].id].selectedValues[0] == "") {
                                es.customMandatoryOptin = true;
                                otherOptins[key].hasError = true;
                            } else {
                                otherOptins[key].hasError = false;
                            }
                            break;
                    }
                }
            });
        }



        const otherData = comp.otherOptins
            .filter(o => !o.disabled && !o.deleted).map(oo => {
                if (!oo.deleted) {
                    if (oo.type === 'text' || oo.type === 'comment') {
                        return {
                            id: oo.id,
                            type: oo.type,
                            value: others[oo.id] ? others[oo.id].value : ''
                        };
                    } else if (oo.type === 'date') {
                        return {
                            id: oo.id,
                            type: oo.type,
                            value: others[oo.id] ? others[oo.id].value : new Date()
                        };
                    } else if (oo.type === 'checkbox' || oo.type === 'dropdown' || oo.type === 'radio') {
                        return {
                            id: oo.id,
                            type: oo.type,
                            values: others[oo.id] ? others[oo.id].selectedValues : []
                        };
                    }
                }
            });

        if (es.username || es.invalidUsername || es.firstname || es.lastname || es.email || es.mobile || es.address
            || es.state || es.postcode || es.tnc || es.password || es.other
            || es.dob || es.confirmAdult || es.customMandatoryOptin) {
                console.log('err', es);

            this.setState({
                ...this.state,
                errStates: es
            });
            return;

        } else {

            // to maintain existing structure in user table
            // same should be removed while checking user and displaying leaderboard
            let finalUsername = `${username}#${comp.id}`;
            this.props.dispatch(signUp(
                {
                    username: finalUsername, firstname, lastname, email: emailToSend,
                    mobile:mobile.value?(mobile.number).replace(/\s+/g, ''):'', address, state,
                    postcode, tnc, optin: marketing,
                    other, password: passwordToSend, member,
                    memberYes, memberNo,
                    dob, confirmAdult,
                    idCompetition: comp.id, otherData
                },
                this.props.dispatch
            ));

            if (comp && comp.webhooks && comp.webhooks.find(wh => wh.type === 'register')) {
                console.log("Register webhook enabled");
                this.props.dispatch(webhookRegister(
                    {
                        username, firstname, lastname, email: emailToSend,
                        mobile:    mobile.value?(mobile.number).replace(/\s+/g, ''):'', address, state,
                        postcode, tnc, optin: marketing,
                        other, password: passwordToSend, member,
                        memberYes, memberNo,
                        idCompetition: comp.id, otherData
                    },
                    this.props.dispatch
                ));
            }

            this.setState({
                ...this.state,
                errStates: es
            });
        }
    }

    handleLogout = () => {
        this.props.dispatch(signOff(this.props.dispatch));
    }

    renderVerificationInfo = () => {

        const {
            verifyingEmail, verifyingEmailSuccess,
            verifyingEmailMessage, registering,
            reauthenticating, logginIn
        } = this.props.auth;

        let noBorderRadius = (this.props.comp && this.props.comp.defaultShape == 2);

        const reauthenticatingDiv = reauthenticating ? (
            <Modal show={true} dialogClassName={(noBorderRadius ? 'no-border-radius' : '')}>
                <Modal.Body>
                    <div className={'logging-in'}>
                        <FontAwesomeIcon className='benchvote-spin' icon={faSpinner} spin/>
                        <Trans i18nKey="retrieving_data" />
                    </div>
                </Modal.Body>
            </Modal>
        ) : null;

        const verifyingEmailDiv = verifyingEmail ? (
            <Modal show={true} dialogClassName={(noBorderRadius ? 'no-border-radius' : '')}>
                <Modal.Body>
                    <div className={'logging-in'}>
                        <FontAwesomeIcon className='benchvote-spin' icon={faSpinner} spin/>
                        <Trans i18nKey="finish_email_verification" />
                    </div>
                </Modal.Body>
            </Modal>
        ) : null;

        const registeringDiv = registering ? (
            <Modal show={true} dialogClassName={(noBorderRadius ? 'no-border-radius' : '')}>
                <Modal.Body>
                    <div className={'logging-in'}>
                        <FontAwesomeIcon className='benchvote-spin' icon={faSpinner} spin/>
                        <Trans i18nKey="creating_account" />
                    </div>
                </Modal.Body>
            </Modal>
        ) : null;

        const logingInDiv = logginIn ? (
            <Modal show={true} dialogClassName={(noBorderRadius ? 'no-border-radius' : '')}>
                <Modal.Body>
                    <div className={'logging-in'}>
                        <FontAwesomeIcon className='benchvote-spin' icon={faSpinner} spin/>
                        Loading...
                    </div>
                </Modal.Body>
            </Modal>
        ) : null;

        const emailVerificationMessage = verifyingEmailMessage ? (<div
            className={`email-verif-message ${verifyingEmailSuccess ? 'success' : 'error'}`}>
            {verifyingEmailMessage}
        </div>) : null;

        return (<div className={(noBorderRadius ? 'no-border-radius' : '')}>
            {reauthenticatingDiv}
            {verifyingEmailDiv}
            {registeringDiv}
            {emailVerificationMessage}
            {logingInDiv}
        </div>);
    }

    isMultipleInterval = () => {
        const {currentRound, rounds} = this.props.competition;
        return (currentRound && rounds && rounds.length > 1);
    }

    renderWelcome = () => {

        let {firstname, lastname} = this.state.user;
        const {comp} = this.props;

        return (<div className={`login-options white-bg ${!this.isMultipleInterval() ? 'single-interval' : ''}`}>
            <div className='action-buttons'>
                <h3><span>{`${firstname}`}</span></h3>
                <div
                    className='button login-btn logout-btn' onClick={this.handleLogout}>
                    <Trans i18nKey="logout" />
                </div>
            </div>
            {/* <RoundSelector /> */}
        </div>);
    }

    renderIniBtns = () => {

        const {comp} = this.props;

        // return (<div className='login-options white-bg'>
        //     {this.renderVerificationInfo()}
        //     <h4><Trans i18nKey="user_account_required" /></h4>
        //     <div style={{backgroundColor: comp ? comp.buttonColor : '', color: comp ? comp.primaryColor : ''}}
        //          className='button login-btn' onClick={this.toLogin}>
        //         <Trans i18nKey="login" />
        //     </div>
        //     <div style={{backgroundColor: comp ? comp.buttonColor : '', color: comp ? comp.primaryColor : '', borderColor: comp ? comp.buttonColor : ''}}
        //          className='button margin red_btn' onClick={this.toRegister}>
        //         <Trans i18nKey="signup" />
        //     </div>
        // </div>)

        return (<div className={`login-options white-bg ${!this.isMultipleInterval() ? 'single-interval' : ''}`}>
            {this.renderVerificationInfo()}
            <div className='action-buttons'>
                <div style={{backgroundColor: comp ? comp.buttonColor : '', color: comp ? comp.primaryColor : ''}}
                    className='button login-btn m-l-0 action-btn-set' onClick={this.toLogin}>
                    <Trans i18nKey="login" />
                </div>
                <div style={{backgroundColor: comp ? comp.buttonColor : '', color: comp ? comp.primaryColor : ''}}
                    className='button red_btn action-btn-set' onClick={this.toRegister}>
                    <Trans i18nKey="signup" />
                </div>
            </div>
            {/* <RoundSelector /> */}
        </div>)
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.loggedIn !== nextProps.auth.loggedIn) {
            this.setState({
                ...this.state,
                loggedIn: nextProps.auth.loggedIn,
                user: nextProps.auth.user
            });
        }
    }

    render() {
        let ret = this.renderIniBtns();
        let {recoverPass} = this.props.auth;

        if (recoverPass && !this.state.closeReset) {
            return this.renderResetStep2();
        } else if (this.state.loggedIn) {
            return this.renderWelcome();
        } else if (this.state.loginEnabled) {
            ret = this.renderLogins();
        } else if (this.state.registerEnabled) {
            ret = this.renderRegistration();
        } else if (this.state.forgotEnabled) {
            ret = this.renderForgot();
        }

        return ret;
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        comp: things.competition.data,
        auth: things.auth,
        locale: things.locale,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VoteStatus));
