import React, {Component} from 'react';

import {Modal, Row, Col, FormControl} from 'react-bootstrap';

import './TippingSelector.css';
import connect from "react-redux/es/connect/connect";

import defaultTeam from '../../assets/images/defaultTeam.png';
import { Trans} from 'react-i18next';
import { getTippingUserSelections } from '../../util/helpers/Helpers';
import { allowScoreMargin } from '../../actions/AuthActions';

class TippingSelector extends Component {

    constructor(props) {

        super(props);

        const selections = this.getSelections(props);

        this.state = {
            selections: selections
        };
        this.selectTeam = this.selectTeam.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('prev props', prevProps);
        // console.log('curr props', this.props);
        // console.log('curr state', this.state);
        // console.log('curr state 2', prevState);
        if (prevProps.competition && prevProps.competition.currentRound
            && this.props.competition && this.props.competition.currentRound
            && prevProps.competition.currentRound.id !== this.props.competition.currentRound.id) {

            console.log('preload selections');
            const selections = this.getSelections();

            this.setState({
                selections: selections
            });

        }
    }

    componentWillUnmount() {
    }

    getSelections = () => {
        const tippingUserSelections = getTippingUserSelections();
        let selections = [];
        console.log('propssss', this.props);
        const { competition } = this.props;

        if (tippingUserSelections && tippingUserSelections.length > 0 && competition.currentRound
            && competition.currentRound.id) {
            const tippingIntervalGames = tippingUserSelections.filter(tus =>
                tus.idInterval === competition.currentRound.id);
            selections[competition.currentRound.id] = [];
            tippingIntervalGames.forEach(tig => {
                let team = {};
                if (tig.idTeam) {
                    let matchingGame = competition.currentRound.tippingGames.find(tg => tg.id === tig.idTippingGame);
                    if (tig.idTeam === matchingGame.idAwayTeam) {
                        team = matchingGame.awayTeam;
                    } else if (tig.idTeam === matchingGame.idLocalTeam) {
                        team = matchingGame.localTeam;
                    } else if (tig.idTeam === 1) {
                        team = {
                            id: 1,
                            name: 'Draw'
                        };
                    }
                }
                if (team !== {}) {
                    selections[competition.currentRound.id].push({
                        tippingGameId: tig.idTippingGame,
                        selection: team,
                        scoreMargin: tig.scoreMargin,
                        marginInput: tig.scoreMargin
                    });
                }
            });
        }

        console.log('selectionsss', selections);

        return selections;
    }

    changeMargin = (tippingGameId, e) => {
        const margin = e.target.value;

        const { selections } = this.state;
        const { competition } = this.props;
        const { currentRound } = competition;

        if (!currentRound.voteOpen) {
            return false;
        }

        if (!selections[currentRound.id]) {
            selections[currentRound.id] = [];
        }

        let team = {};

        const tippingGame = selections[currentRound.id].find(s => s.tippingGameId === tippingGameId);
        const notTippingGame = selections[currentRound.id].filter(s => s.tippingGameId !== tippingGameId);
        if (tippingGame) {
            tippingGame.scoreMargin = margin;
            if (!tippingGame.selection) {
                tippingGame.selection = team;
            }
            tippingGame.marginInput = margin;
            notTippingGame.push(tippingGame);
        } else {
            notTippingGame.push({
                tippingGameId,
                scoreMargin: margin,
                marginInput: margin,
                selection: team
            });
        }
        selections[currentRound.id] = notTippingGame;
        this.setState({
            selections: selections
        });
        this.props.onSelectionChange(selections);
    }

    selectTeam(tippingGameId, team) {
        const {selections} = this.state;
        const {competition} = this.props;
        const {currentRound} = competition;

        if (!currentRound.voteOpen) {
            return false;
        }

        if (!selections[currentRound.id]) {
            selections[currentRound.id] = [];
        }

        if (!team) {
            team = {
                id: 1,
                name: 'Draw'
            };
        }

        const tippingGame = selections[currentRound.id].find(s => s.tippingGameId === tippingGameId);
        const notTippingGame = selections[currentRound.id].filter(s => s.tippingGameId !== tippingGameId);
        if (tippingGame) {
            tippingGame.selection = team;
            tippingGame.scoreMargin = !team ? null : tippingGame.scoreMargin;
            tippingGame.marginInput = !team ? null : tippingGame.scoreMargin;
            notTippingGame.push(tippingGame);
        } else {
            notTippingGame.push({
                tippingGameId,
                selection: team
            });
        }
        selections[currentRound.id] = notTippingGame;
        this.setState({
            selections: selections
        });
        this.props.onSelectionChange(selections);
    }

    render() {
        const {selections} = this.state;
        const {competition} = this.props;
        const {currentRound, data} = competition;

        return (
            <div className="tipping-container">
                {currentRound.tippingGames && currentRound.tippingGames.filter(tg => tg.enabled).map(tg => {

                    const localSelected = selections && selections[currentRound.id]
                        && selections[currentRound.id].find(s => s.tippingGameId === tg.id
                            && s.selection.id === tg.localTeam.id);

                    const awaySelected = selections && selections[currentRound.id]
                        && selections[currentRound.id].find(s => s.tippingGameId === tg.id
                            && s.selection.id === tg.awayTeam.id);

                    const drawSelected = selections && selections[currentRound.id]
                        && selections[currentRound.id].find(s => s.tippingGameId === tg.id
                            && s.selection.id === 1);


                    // const tippingGameRound = selections && selections[currentRound.id]
                    //     && selections[currentRound.id].find(s => s.tippingGameId === tg.id);

                    // const tippingUserSelections = getTippingUserSelections();

                    // const tippingGameMatch = tippingUserSelections && tippingUserSelections.find(tus =>
                    //             tus.idInterval === currentRound.id && tus.idTippingGame === tg.id);

                    // const localSelected = (selections && selections[currentRound.id]
                    //     && selections[currentRound.id].find(s => s.tippingGameId === tg.id
                    //         && s.selection.id === tg.localTeam.id))
                    //         || (tippingGameMatch && tippingGameMatch.idTeam === tg.localTeam.id);

                    // const awaySelected = (selections && selections[currentRound.id]
                    //     && selections[currentRound.id].find(s => s.tippingGameId === tg.id
                    //         && s.selection.id === tg.awayTeam.id))
                    //         || (tippingGameMatch && tippingGameMatch.idTeam === tg.awayTeam.id);

                    // const drawSelected = (selections && selections[currentRound.id]
                    //     && selections[currentRound.id].find(s => s.tippingGameId === tg.id
                    //         && s.selection.id === 1))
                    //         || (tippingGameMatch && tippingGameMatch.idTeam === 1);
                    
                    // tg.marginInput = tippingGameRound && tippingGameRound.marginInput
                    //                     ? tippingGameRound.marginInput : "";
                    // if (tippingGameMatch && (localSelected || awaySelected)) {
                    //     tg.marginInput = tippingGameMatch.scoreMargin;
                    // }

                    const selectedGame = selections && selections[currentRound.id]
                        && selections[currentRound.id].find(s => s.tippingGameId === tg.id);

                    tg.marginInput = drawSelected ? 0 : (selectedGame && selectedGame.marginInput
                                        ? selectedGame.marginInput : "");

                    // const marginInputRow = document.querySelector("#margin-input-row-"+tg.id);
                    // var input  = document.createElement("input");
                    // input.type = "number";
                    // input.addEventListener('change', function() {
                    //     alert(input.value);
                    // });
                    // if (marginInputRow) {
                    //     // marginInputRow.innerHTML = "<input type='number' value='' onchange='this.changeMargin(tg.id, e)'>";
                    //     marginInputRow.append(input);
                    // }

                    
                    // if (!currentRound.voteOpen || drawSelected) {
                    //     const marginInput = document.querySelector("#margin-input-"+tg.id);
                    //     if (marginInput) {
                    //         marginInput.setAttribute("disabled", "disabled");
                    //     }
                    // }

                    return (<div className={`gameLine interval-${tg.idInterval}`}>
                        {/* <Col smHidden xsHidden md={1}/> */}
                        <Col
                             style={{
                                 color:  localSelected? '' : data.primaryColor,
                                 borderColor: data.borderColor?data.borderColor : '#ffffff',
                                 backgroundColor: data.backgroundColor?data.backgroundColor : '',
                             }}
                             className={ localSelected ? 'selectable selected' : 'selectable'}
                             onClick={() => this.selectTeam(tg.id, tg.localTeam)}>
                            
                            {tg.localTeam.logoUrl ?
                                <img className={'logoImage'} src={tg.localTeam.logoUrl}/> 
                                : <img className={'logoImage'} src={defaultTeam}/>
                                // <img src={tg.localTeam.logoUrl} className="teamLogo"/>
                                // : <img src={defaultTeam} className="teamLogo"/> }
                            }
                            {tg.localPoints ?
                            <div className="tipping-team-points custom-vote-colour">{tg.localPoints}</div>
                            : ''}
                            <div className="teamName custom-vote-colour">{tg.localTeam.name}</div>
                        </Col>
                        <Col className={"vs-section"}>
                            <Row className={`vs-box custom-vote-colour ${allowScoreMargin(this.props.comp) ? 'only-mobile' : ''}`}><Trans i18nKey="vs" /></Row>
                            {/* draw for desktop view */}
                            <Row
                                style={{ borderColor: data.borderColor?data.borderColor : '#ffffff',
                                backgroundColor: data.backgroundColor?data.backgroundColor : '',
                                color:  drawSelected? '' : data.primaryColor}}
                                className={drawSelected ? 'draw-box draw-desktop draw-selected' : 'draw-box draw-desktop'}
                                onClick={() => this.selectTeam(tg.id)}>
                                <Trans i18nKey="draw" />
                            </Row>
                            { allowScoreMargin(this.props.comp) ?
                                <Row id={'margin-input-row-' + tg.id}
                                    className={'margin-box custom-vote-colour draw-desktop'}>
                                    {/* <Trans i18nKey="draw" /> */}
                                    {/* MARGIN */}
                                    {/* {!drawSelected ? */}
                                    <FormControl
                                        style={{ borderColor: data.borderColor ? data.borderColor : '#ffffff',
                                        backgroundColor: data.backgroundColor ? data.backgroundColor : '',
                                        color: data.primaryColor}}
                                        type="number"
                                        placeholder="MARGIN"
                                        // placeholder={t('e-mail')}
                                        disabled={(!currentRound.voteOpen || drawSelected) ? "disabled" : false}
                                        // id={'margin-input-' + tg.id}
                                        value={tg.marginInput}
                                        onChange={(e) => this.changeMargin(tg.id, e)}
                                    />
                                </Row>
                            : ''}
                        </Col>
                        <Col
                             style={{
                                 color:  awaySelected? '' : data.primaryColor,
                                 borderColor: data.borderColor?data.borderColor : '#ffffff',
                                 backgroundColor: data.backgroundColor?data.backgroundColor : '',
                             }}
                             className={ awaySelected ? 'selectable selected' : 'selectable'}
                             onClick={() => this.selectTeam(tg.id, tg.awayTeam)}>
                            
                            {tg.awayTeam.logoUrl ?
                                <img className={'logoImage'} src={tg.awayTeam.logoUrl}/> 
                                : <img className={'logoImage'} src={defaultTeam}/>
                                // <img src={tg.awayTeam.logoUrl} className="teamLogo"/>
                                // : <img src={defaultTeam} className="teamLogo"/> }
                                }
                            
                            {tg.awayPoints ?
                            <div className="tipping-team-points custom-vote-colour">{tg.awayPoints}</div>
                            : ''}
                            <div className="teamName custom-vote-colour">{tg.awayTeam.name}</div>
                            
                        </Col>
                        {/* draw for mobile view */}
                        <Row
                         style={{ borderColor: data.borderColor?data.borderColor : '#ffffff',
                         backgroundColor: data.backgroundColor?data.backgroundColor : '',
                         color:  drawSelected?'' :  data.primaryColor}}
                            className={drawSelected ? 'draw-box draw-mobile draw-selected' : 'draw-box draw-mobile'}
                            onClick={() => this.selectTeam(tg.id)}>
                            <Trans i18nKey="draw" />
                        </Row>
                         { allowScoreMargin(this.props.comp) ?
                        <Row
                            className={'margin-box custom-vote-colour only-mobile'}>
                            {/* <Trans i18nKey="draw" /> */}
                            {/* MARGIN */}
                            <FormControl
                                style={{ borderColor: data.borderColor ? data.borderColor : '#ffffff',
                                backgroundColor: data.backgroundColor ? data.backgroundColor : '',
                                color: data.primaryColor}}
                                type="number"
                                placeholder="MARGIN"
                                disabled={(!currentRound.voteOpen || drawSelected) ? "disabled" : false}
                                // placeholder={t('e-mail')}
                                value={tg.marginInput}
                                onChange={(e) => this.changeMargin(tg.id, e)}
                            />
                        </Row>
                        : ''}
                        {/* <Col smHidden xsHidden md={1}/> */}
                    </div>);
                })}
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        comp: things.competition.data,
        auth: things.auth,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TippingSelector);
