import React, {Component} from 'react';
import {connect} from 'react-redux';

import './RoundSelector.css';
import {selectRound} from '../../actions/CompetitionActions';
import { Trans} from 'react-i18next';

class RoundSelector extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    selectChanged = (e) => {
        this.selectRound(parseInt(e.target.value));
    }

    selectRound = (idRound) => {

        const newRound = this.props.competition.rounds.find(r => r.id === idRound);
        this.props.dispatch(selectRound(
            newRound,
            this.props.dispatch
        ));
    }

    render() {

        const {rounds, currentRound, data} = this.props.competition;
        if (!currentRound)
            return null;

        const availableRounds = rounds.map(r => {
            return (<div
                key={r.id}
                // style={{backgroundColor: data ? data.secondaryColor : ''}}
                className={`roun-nums ${currentRound && r.id === currentRound.id ? 'sel-round' : ''}`}
                style={{
                    backgroundColor: data && currentRound && r.id === currentRound.id ? data.primaryColor : '',
                    color: data && currentRound && r.id === currentRound.id ? data.secondaryColor : '',
                }}
                onClick={() => this.selectRound(r.id)}>
                <div>{r.name}</div>
            </div>);
        })
        const availableRoundsSelect = rounds.map(r => {
        return (<option value={r.id} selected={r.id === currentRound.id?true:false} >{r.name}</option>);
        
        })
        return rounds ? (
            rounds.length <= 1 ? null : (
            <div
                 className='round-selector'>
                <div
                     className={`legend ${currentRound && 1 === currentRound.id ? 'first' : ''}`}>
                        <Trans i18nKey="select_a_round" />
                </div>
                {/* <div className='round-select-desktop'>
                    {availableRounds}
                </div>*/}
                <select className='round-select-desktop' onChange={this.selectChanged}>
                    {availableRoundsSelect}
                </select>
                <select className='form-control round-select-mobile' onChange={this.selectChanged}>
                    {availableRoundsSelect}
                </select>
            </div>
            )
        ) : null;
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoundSelector);
