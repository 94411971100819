import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Trans} from 'react-i18next';
import Login from "./Login";


import './VoteStatus.css';

class VoteStatus extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.onRef)
            this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef)
            this.props.onRef(undefined);
    }

    toInitial = () => {
       this.child.toInitial(); 
    }

    toRegister = () => {
       this.child.toRegister();
    }

    toLogin = () => {
       this.child.toLogin();
    }

    render() {

        const data = this.props.competition.data;
        if(!data) {
            return null;
        }

        return (
            <div className={`header-block`}>
                <div className={'header-content'}>
                    <Login onRef={ref => (this.child = ref)} />
                </div>
            </div>
        );
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        auth: things.auth,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoteStatus);
