import React, { Component } from "react";

import { Modal, Row, Col } from "react-bootstrap";

import "./VideoSelector.css";
import connect from "react-redux/es/connect/connect";
import { Trans } from 'react-i18next';

class VideoSelector extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleCloseWarning = this.handleCloseWarning.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectVideo = this.handleSelectVideo.bind(this);
    this.reproduce = this.reproduce.bind(this);

    this.state = {
      show: false,
      selection: [],
      sChange: false,
      notLoggedIn: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.competition.dataAvailable &&
      this.props.competition.dataAvailable
    ) {
    }
    if (!prevProps.auth.votes && this.props.auth.votes) {
      const { comp, competition } = this.props;
      if (comp) {
        const { currentRound } = competition;
        const videos = currentRound.videos.filter(p => p.isVideo);
        const selPl = this.props.auth.votes.reduce((acc, v) => {
          if (acc && !acc.find(i => i === v.idInterval)) {
            if (!acc[v.idInterval]) {
              acc[v.idInterval] = [];
            }
            acc[v.idInterval].push(videos.find(v2 => v2.id === v.idPlayer));
          }
          return acc;
        }, []);

        this.setState({
          selection: selPl,
          show: false,
          sChange: JSON.stringify(selPl)
        });

        this.props.onSelectionChange(selPl);
      }
    }
  }

  componentWillUnmount() {}

  handleClose = () => {
    this.setState({
      show: false,
      video: undefined
    });
  };

  reproduce = video => {
    this.setState({
      show: true,
      video
    });
  };

  handleSelectVideo = video => {
    if (!this.props.comp) return;

    const { competition } = this.props;
    const { currentRound } = competition;

    const selection = this.state.selection;
    selection[currentRound.id] = [video];

    this.setState({
      selection
    });

    this.props.onSelectionChange(selection);
  };

  handleShow = () => {
      console.log(this.props.auth);
    if (!this.props.auth.loggedIn) {
      this.setState({
        notLoggedIn: true
      });
      return;
    }
  };

  handleCloseWarning = () => {
    this.setState({
      notLoggedIn: false
    });
  };


    renderIniBtns = () => {
        return (<div className='login-options'>
        <div
            className='button margin login-btn' onClick={() => {this.handleCloseWarning(); this.props.onTriggerRegisterLogin('login'); }}>
            <Trans i18nKey="login" />
        </div>
        <div
             className='button red_btn' onClick={() => {this.handleCloseWarning(); this.props.onTriggerRegisterLogin('register');  }}>
            <Trans i18nKey="register" />
        </div>
    </div>)
    }

  render() {
    const { show, video, selection } = this.state;
    const { comp, competition } = this.props;
    if (!competition || !competition.currentRound) {
      return null;
    }

    const { currentRound } = competition;
    const videos = currentRound.videos.filter(p => p.isVideo);
    videos.map(obj => {
      if (obj.headshotSmall) {
        obj.headshot = obj.headshotSmall;
      } else {
        obj.headshot = "https://img.youtube.com/vi/" + obj.videoUrl + "/0.jpg";
      }
    });

    return (
      <div
        className={"videos-container"}
        style={
          comp.fieldBg
            ? {
                backgroundImage: `url(${comp.fieldBg.file})`
              }
            : {}
        }
      >
        <Row>
          {videos.map(v => (
            <Col xs={12} sm={6} md={4} className="video-container">
              <div
                onClick={() => this.handleSelectVideo(v)}
                className="video-thumb-cont"
                style={{borderColor:comp.borderColor?comp.borderColor:'#ffffff',backgroundColor:comp.backgroundColor?comp.backgroundColor:''}}
              >
                <div className="video-play-image">
                  <i class="fa fa-play fa-5x play_icon" aria-hidden="true"></i>
                  <img src={v.headshot} onClick={() => this.reproduce(v)} />
                </div>
                <div class="caption-container"
                style={this.props.auth.loggedIn && selection &&
                 selection[currentRound.id] &&
                 selection[currentRound.id].find(s => {
                   return s && s.id === v.id;
                 }) ?{backgroundColor: '#4caf50',borderRadius: '0 0 8px 8px',color:'#ffffff'}:{}}
                  >
                  <h1
                    className="custom-vote-colour"
                    // onClick={() => this.reproduce(v)}
                    // style={{
                    //   color: comp ? comp.secondaryColor : ""
                    // }}
                  >
                    {v.firstname}
                  </h1>
                  {/* <h2
                                        onClick={() => this.reproduce(v)}
                                        style={{
                                            color: comp ? comp.secondaryColor : ''
                                        }}>{v.lastname}</h2> */}
                  {this.props.auth.loggedIn &&
                  selection &&
                  selection[currentRound.id] &&
                  selection[currentRound.id].find(s => {
                    return s && s.id === v.id;
                  }) ? (
                    // <div className="selected"></div>
                    <div className="selected-tick-block">
                      <svg className="custom-tick-colour"
                          width="33px" height="33px" viewBox="0 -1 25 25" version="1.1">
                          <g id="V1" stroke="none" stroke-width="1">
                              <g id="1.1-MVP-Selector" transform="translate(-479.000000, -473.000000)">
                                  <g id="Group-Copy" transform="translate(362.000000, 462.000000)">
                                      <path d="M129.6,11.2 C126.417737,11.2 123.365486,12.4642926 121.114149,14.7141486 C118.864155,16.9652114 117.6,20.0177371 117.6,23.2 C117.6,26.3822629 118.864293,29.4345143 121.114149,31.6858514 C123.365211,33.9358446 126.417737,35.2 129.6,35.2 C132.782263,35.2 135.834514,33.9357074 138.085851,31.6858514 C140.335845,29.4347886 141.6,26.3822629 141.6,23.2 C141.6,20.0177371 140.335707,16.9654857 138.085851,14.7141486 C135.834789,12.4641554 132.782263,11.2 129.6,11.2 Z M127.037457,28.2322581 L123.019355,22.953063 L124.352977,21.8397633 L127.173965,25.5387912 L134.192513,18.1677419 L135.406452,19.4426496 L127.037457,28.2322581 Z" id="Fill-1"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                    </div>
                  ) : this.props.auth.loggedIn ? (
                    <div
                      onClick={() => this.handleSelectVideo(v)}
                      style={{
                        backgroundColor: comp.buttonColor,
                        color: comp.primaryColor
                      }}
                      className="button"
                    ></div>
                  ) :  (<div onClick={()=>this.handleShow()} className="vote-now"><Trans i18nKey="vote_now" /></div>)}
                </div>
              </div>
            </Col>
          ))}
          {this.props.auth.loggedIn ? null : (
            <Col sm={12}>
              {/* <div className="please-log-in"> Please login to enable voting
                        </div> */}
            </Col>
          )}
        </Row>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Body>
            <div>
              {video && (
                <iframe
                  className="youtube-iframe"
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.videoUrl}`}
                  frameBorder="0"
                  allow="autoplay=1; encrypted-media"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.notLoggedIn}
          onHide={this.handleCloseWarning}
          dialogClassName={"warningModal"}
        >
          <Modal.Body>
            <div className="warningCopy">
              <Trans i18nKey="login_to_participate" />
              <br />
            </div>
            <div className="login-register-btn">  {this.renderIniBtns()}</div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        comp: things.competition.data,
        auth: things.auth,
        locale: things.locale,
    }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoSelector);
