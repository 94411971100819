import React, {Component} from 'react';
import {connect} from 'react-redux';

import './RoundInfo.css';
import { Trans} from 'react-i18next';


class RoundSelector extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    render() {

        const { currentRound, data } = this.props.competition;

        let roundInfo = (
            <div
                style={{backgroundColor: '#2C2F30', color: '#FFFFFF', borderRadius: '10px'}}
                // style={{backgroundColor: data ? data.secondaryColor : '', color: data ? data.primaryColor : ''}}
                className='round-info'>
                <p className='loading-it'>
                <Trans i18nKey="loading_rounds" />
                </p>
            </div>
        );

        if (currentRound) {

            const {localPoints1, localPoints2, awayPoints1, awayPoints2, localTeam, awayTeam} = currentRound;
            const localLogo = currentRound.localTeam ? currentRound.localTeam.logo : undefined;
            const awayLogo = currentRound.awayTeam ? currentRound.awayTeam.logo : undefined;

            if (!localPoints1 && !localPoints2 && !awayPoints1 && !awayPoints2 &&
                  JSON.stringify(localTeam) == '{}' && JSON.stringify(awayTeam) == '{}') {
                    roundInfo = null;
                } else {
                    roundInfo = (
                        <div
                            style={{backgroundColor: '#2C2F30', color: '#FFFFFF', borderRadius: '10px'}}
                            // style={{backgroundColor: data ? data.secondaryColor : '', color: data ? data.primaryColor : ''}}
                            className='round-info'>
                            <div className='team left'>
                                <div className='logo' style={{
                                    backgroundImage: `url('${localLogo}')`
                                }}></div>
                                <div className='small-number'>{localPoints2 ? localPoints2 : ''}</div>
                                <div className='big-number'>{localPoints1 ? localPoints1 : ''}</div>
                            </div>
                            <div className='team right'>
                                <div className='logo' style={{
                                    backgroundImage: `url('${awayLogo}')`
                                }}></div>
                                <div className='small-number'>{awayPoints2 ? awayPoints2 : ''}</div>
                                <div className='big-number'>{awayPoints1 ? awayPoints1 : ''}</div>
                            </div>
                            <div className='legend'><Trans i18nKey="game_details" /></div>
                            <div className='vs'><Trans i18nKey="vs" /></div>
                            <div className='place'>{currentRound.gameDetails}</div>
                        </div>);
                }
        }

        return currentRound && (currentRound.localTeam || currentRound.awayTeam) ? roundInfo : null;
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoundSelector);
