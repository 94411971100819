import React, {Component} from 'react';
import {connect} from 'react-redux';

import './MarketingButtons.css';

class MarketingButtons extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    render() {

        const {currentRound, data} = this.props.competition;

        // let marketingButtons = (
        //     <div
        //         style={{backgroundColor: data ? data.secondaryColor : '', color: data ? data.primaryColor : ''}}
        //         className='marketing-buttons'>
        //     </div>
        // );
        let marketingButtons = null;

        if (data && data.marketingButtons && data.marketingButtons.length
            && this.props.competition.submitted && this.props.competition.submittedIdInterval === currentRound.id) {

            marketingButtons = (

                <div class="row">
                    <div class="col-sm-12">
                        <div
                            style={{color: data ? data.primaryColor : ''}}
                            className='form-inline text-center marketing-buttons'>
                            {data.marketingButtons.map(mb =>
                                <div class="form-group">
                                    <a style={{
                                        backgroundColor: data ? data.buttonColor : ''
                                    }}
                                    className='button margin'
                                    target={mb.target}
                                    href={mb.url}>
                                        <div style={{
                                            color: data ? data.primaryColor : ''
                                        }} className="marketingButtonCont">
                                            {mb.label}
                                        </div>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                
                );
        }

        return currentRound ? marketingButtons : marketingButtons;
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingButtons);
