import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';


import './TotalLeaderboard.css';

class TotalLeaderboardItems extends Component {

    constructor(props) {

        super(props);
        this.state = {
            pageLoading: false,
        };

    }

    render() {

        let fullLeaderboards = this.props.leaderboardData;
        let alignPanelLeft = (this.props.panelNum === 1 && this.props.showTwoPanels);
        let alignPanelRight = (this.props.panelNum === 2);

        const { comp, t } = this.props;
        const currentRound = this.props.competition.currentRound;

        if (!currentRound) {
            return null;
        }

        const theEnd = moment(new Date(currentRound ? currentRound.end : 0)).format('dddd Do, h:mm a');

        return (

            <div>

                <div className={`total-leaderboard ${alignPanelLeft ? 'total-leaderboard-left' : ''}
                        ${alignPanelRight ? 'total-leaderboard-right' : ''}`} style={{
                    color: comp ? comp.primaryColor : '',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: comp.borderColor ? comp.borderColor : '#ffffff',
                    backgroundColor: comp.backgroundColor ? comp.backgroundColor : '',
                }}>
                    <div className='lb-body'>
                        {fullLeaderboards && fullLeaderboards.length > 0
                            ? <table className='leaderboard-table'>
                                <thead>
                                    <tr>
                                        <th>Position</th>
                                        <th>Username</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fullLeaderboards && fullLeaderboards.map(l =>
                                    (
                                        <tr className={`lb-place ${l.rank % 2 ? 'odd-row' : 'even-row'}`} key={JSON.stringify(l)}>
                                            <td className=''>{l.rank}</td>
                                            <td className=''>{`${l.username}`}</td>
                                            <td className=''>{l.score}</td>
                                            {/* <td className='player'>{`${l.username}`}</td>
                                        <td className='points'>{l.score}</td> */}
                                        </tr>
                                    ))}
                                </tbody></table>
                            : this.props.competition.pageLoading
                                ? (<div className='lb-place center'><Trans i18nKey="loading" /></div>)
                                : (<div
                                    style={{ color: comp ? comp.primaryColor : '' }}
                                    className='lb-place center'>
                                    {currentRound ?
                                        `${t('voting_closes_on')} ${theEnd}.` : ''}
                                </div>)}
                    </div>
                </div>


            </div>
        );
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        comp: things.competition.data,
        competition: things.competition,
        locale: things.locale,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TotalLeaderboardItems));
