import {browserHistory} from 'react-router';

const initialState = {
    loggedIn: false,
    processing: false,
    errMessage: '',
    logginIn: false,
    verifyingEmail: false,
    user: {},
    votes: undefined,
    shares: [],
    loggedOffNow: false,
    alreadyLoggedIn: false,
    reauthenticating: false,
    recovering: false,
    recovered: false,
    recoverSuccess: false,
    recoverMessage: '',
    recoverCode: '',
    recoverPass: '',
    optingIn: false,
    optedInExternal: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case 'START_RESET_START': {
            return {
                recoverPass: action.payload.recoverPass
            }
        }
        case 'OPTIN_START': {
            return {
                ...state,
                optingIn: true,
                optedInExternal: false
            }
        }
        case 'OPTIN_FINISH': {
            return {
                ...state,
                optingIn: false,
                optedInExternal: true
            }
        }
        case 'TWITTER_LOGIN_START':
            return {
                ...state,
                logginIn: true,
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        case 'TWITTER_LOGIN_CONTINUE':
            return {
                ...state,
                logginIn: true,
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        case 'TWITTER_LOGIN_FINISH':
            if (action.payload.errorCode === "0") {
                let url = `https://api.twitter.com/oauth/authenticate?oauth_token=${action.payload.requestToken}`;
                if(window.self !== window.top) {
                    window.open(url, '_top');
                } else {
                    window.location.href = url;
                }
            }
            return {
                ...state,
                logginIn: false,
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        case 'VERIFING_AUTHENTICATION': {
            return {
                ...state,
                loggedIn: false,
                processing: false,
                errMessage: '',
                user: {},
                votes: undefined,
                shares: [],
                loggedOffNow: false,
                alreadyLoggedIn: false,
                reauthenticating: true
            }
        }
        case 'VERIFING_AUTHENTICATION_ERROR': {
            return {
                ...state,
                loggedIn: false,
                processing: false,
                errMessage: '',
                user: {},
                votes: undefined,
                shares: [],
                loggedOffNow: false,
                alreadyLoggedIn: false,
                reauthenticating: false
            }
        }
        case 'FORGOT_START': {
            return {
                ...state,
                recovering: true,
                recovered: false,
                recoverSuccess: false,
                recoverMessage: '',
                recoverCode: ''
            }
        }
        case 'FORGOT_FINISH': {
            let success = action.payload.errorCode === "0" ? true : false;
            return {
                ...state,
                recovering: false,
                recovered: true,
                recoverSuccess: success,
                recoverMessage: action.payload.message,
                recoverCode: action.payload.errorCode
            }
        }
        case 'RESET_START': {
            return {
                ...state,
                recovering: true,
                recovered: false,
                recoverSuccess: false,
                recoverMessage: '',
                recoverCode: ''
            }
        }
        case 'RESET_FINISH': {
            let success = action.payload.errorCode === "0" ? true : false;
            return {
                ...state,
                recovering: false,
                recovered: true,
                recoverSuccess: success,
                recoverMessage: action.payload.message,
                recoverCode: action.payload.errorCode
            }
        }
        case 'POST_AUTHENTICATE_START':
            return {
                ...state,
                loggedIn: false,
                processing: true,
                errMessage: '',
                logginIn: true,
                user: {},
                votes: undefined,
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        case 'POST_AUTHENTICATE_FINISH': {
            let success = action.payload.errorCode === "0" ? true : false;
            console.log('auth-reduce', success);
            if (success) {
                window.location.reload();
            }
            return {
                ...state,
                loggedIn: success,
                processing: false,
                logginIn: false,
                errMessage: !success ? action.payload.message : '',
                user: success ? action.payload.tokenContent : {},
                votes: success ? action.payload.tokenContent.votes : [],
                shares: success ? action.payload.tokenContent.shares : [],
                loggedOffNow: false,
                alreadyLoggedIn: true,
                reauthenticating: false
            }
        }
        case 'POST_LOGIN_AUTHENTICATE_FINISH': {
            let success = action.payload.errorCode === "0" ? true : false;
            if (success) {
                window.location.reload();
            }
            return {
                ...state,
                loggedIn: success,
                processing: false,
                logginIn: false,
                registering: false,
                registered: true,
                registrationError: !success,
                registrationErrorCode: !success ? action.payload.errorCode : '',
                registrationSuccess: success,
                registrationMessage: !success ? action.payload.message : '',
                user: success ? action.payload.tokenContent : {},
                votes: success ? action.payload.tokenContent.votes : [],
                shares: success ? action.payload.tokenContent.shares : [],
                loggedOffNow: false,
                alreadyLoggedIn: true,
                reauthenticating: false
            }
        }
        case 'POST_AUTHENTICATE_FINISH_2': {
            let success = action.payload.errorCode === "0" ? true : false;
            return {
                ...state,
                loggedIn: success,
                processing: false,
                logginIn: false,
                errMessage: '',
                user: success ? action.payload.tokenContent : {},
                votes: success ? action.payload.tokenContent.votes : [],
                shares: success ? action.payload.tokenContent.shares : [],
                loggedOffNow: false,
                alreadyLoggedIn: true,
                reauthenticating: false
            }
        }
        case 'EMAIL_VERIFICATION_START':
            return {
                ...state,
                verifyingEmail: true,
                verifyingEmailSuccess: false,
                verifyingEmailMessage: '',
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        case 'EMAIL_VERIFICATION_FINISH': {

            let success = action.payload.errorCode === "0" ? true : false;

            return {
                ...state,
                verifyingEmail: false,
                verifyingEmailSuccess: success,
                verifyingEmailMessage: action.payload.message,
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        }
        case 'POST_UNAUTHENTICATE_START':
            return {
                ...state,
                loggedIn: false,
                processing: true,
                errMessage: '',
                user: {},
                loggedOffNow: false,
                alreadyLoggedIn: false
            }
        case 'POST_UNAUTHENTICATE_FINISH':

            let success = action.payload.errorCode === "0" ? true : false;
            return {
                ...state,
                loggedIn: success,
                processing: false,
                errMessage: '',
                user: {},
                votes: undefined,
                shares: [],
                loggedOffNow: true,
                alreadyLoggedIn: false
            }
        case 'POST_AUTHENTICATE_ERROR':
            return {
                ...state,
                ...state.auth,
                loggedIn: false,
                processing: false,
                errMessage: '',
                verifyingEmail: false,
                verifyingEmailMessage: '',
                verifyingEmailSuccess: false,
                user: {},
                loggedOffNow: false,
                alreadyLoggedIn: false,
                recovering: false,
                recovered: false,
                recoverSuccess: false,
                recoverMessage: '',
                recoverCode: ''
            }
        case 'POST_SIGNUP_START': {
            return {
                ...state,
                registering: true,
                registered: false,
                registrationError: false,
                registrationErrorCode: false,
                registrationSuccess: false,
                registrationMessage: '',
                alreadyLoggedIn: false
            }
        }
        case 'POST_SIGNUP_FINISH': {
            let success = action.payload.errorCode === "0" ? true : false;
            return {
                ...state,
                registering: false,
                registered: true,
                registrationError: !success,
                registrationErrorCode: !success ? action.payload.errorCode : '',
                registrationSuccess: success,
                registrationMessage: !success ? action.payload.message : '',
                alreadyLoggedIn: false
            }
        }
        default:
            return state
    }
}
