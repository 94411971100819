import {
    buildHeaders, buildHeadersAuthenticated, defaultCatchCallback, generateRequest,
    generateUrl, generateUrlApiV2
} from '../helpers/MiddlewareHelpers';

const RestApiClient = ({
                           apiV2 = false,
                           relativeUrl,
                           authenticated = false,
                           token = '',
                           type = 'GET',
                           successCallback = res => res,
                           catchCallback = defaultCatchCallback,
                           payload = {},
                       }) => {

    // -------------------------------------------------------------------------------
    // ---------------------- Build headers, url and request -------------------------
    // -------------------------------------------------------------------------------
    const headers = authenticated ? buildHeadersAuthenticated(token, apiV2) : buildHeaders();
    const url = !apiV2 ? generateUrl(relativeUrl) : generateUrlApiV2(relativeUrl);
    // const url = 'http://localhost:3000' + relativeUrl;
    
    const request = generateRequest(url, type, headers, payload);

    // -------------------------------------------------------------------------------
    // ------------------------------- Make API call ---------------------------------
    // -------------------------------------------------------------------------------

    fetch(request).then(response => {
        // Token expired.
        if (response.status === 401) {
            console.error("Token is invalid. response: ", response)
            return;
        }
        // API logic error. Pass error to "catch" callback.
        else if (!response.ok && response.status !== 400) {
            throw Error(response);
        }
        // API success. Pass to next "then" callback.
        return response.json();
    })
        .then(response => {
            // On 401's, the response is undefined.
            if (response === undefined) {
                return;
            }
            // There is a validation error with your payload.
            else if (response.error_code) {
                catchCallback(response);
            }
            // Success callback.
            else {
            successCallback(response);
            }
        })
        .catch((e) => {
            // Handle an API logic error.
            catchCallback(e);
        })
};

export default RestApiClient;
