import React, {Component} from 'react';
import {ControlLabelButton} from 'react-bootstrap';
import {connect} from 'react-redux';
import './Widget.css';
import PartialLeaderboard from './components/TotalLeaderboard'
import {getLeaderboards} from "../actions/CompetitionActions";
import { Trans } from "react-i18next";

class Widget extends Component {

    constructor(props) {

        super(props);

        this.props.dispatch(getLeaderboards(
            this.props.dispatch
        ));
    }

    goToComp = () =>{
        window.open('http://www.afc.com.au/fans/hertz-fans-mvp');
    }

    render() {

        return (
            <div className='content'>
                <PartialLeaderboard widget={true}/>
                <div className='go-to-competition'
                     onClick={this.goToComp}><Trans i18nKey="vote_now" /></div>
            </div>
        );
    }
}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const prj = state.default;
    return {
        ...ownProps,
        locale: prj.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
