import React, { Component } from 'react';
import { connect } from 'react-redux';
import Field from './Field';
import { Modal } from 'react-bootstrap';
import './Voting.css';
import { dynamicRender, renderShare, renderShareProcess, submitVote,
    trackBlockClick, getAllCompSelectionDataFromOmnihub } from "../../actions/CompetitionActions";
import $ from 'jquery';

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter } from '@fortawesome/fontawesome-free-brands/index'
import { faSpinner } from '@fortawesome/fontawesome-free-solid/index'
import VideoSelector from "./VideoSelector";
import TippingSelector from "./TippingSelector";
import { Trans, withTranslation } from 'react-i18next';
import FadeLoader from "react-spinners/FadeLoader";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    PinterestIcon,
    LinkedinIcon,
    PocketIcon,
    RedditIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton
} from "react-share";

import { FaSms, FaBuffer, FaWordpress, FaFlipboard, FaSkype } from "react-icons/fa";
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import CustomShare from '../components/custom-share';
import { FACEBOOK_APP_ID } from '../../util/Constants';
import { decodeOtherLanguage, getTippingUserSelections } from '../../util/helpers/Helpers';
import { allowScoreMargin } from '../../actions/AuthActions';



class Voting extends Component {

    constructor(props) {

        super(props);

        this.socialShareRef1 = React.createRef();
        this.socialShareRef2 = React.createRef();
        this.state = {
            selectedPlace: null,
            willingToPlay: false,
            showLoadCampaign: false,
            clickLoadCampaign: false,
            ohbData: {},
            notLoggedIn: true,
            selectedFilters: [],
            compSelectionData: {},
            showCampaignLoadPopup: false,
            popupLoading: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeSelection = this.changeSelection.bind(this);
        this.handleSelectPlayer = this.handleSelectPlayer.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeShare = this.handleChangeShare.bind(this);
        this.toInitial = this.toInitial.bind(this);
    }

    componentDidMount() {
        window.addEventListener('message', this.toInitial);
    }

    changeSelection = (selections) => {
        this.setState({ selections });
    }

    handleSubmit = (idInterval) => {

        const { t } = this.props;
        const { selections } = this.state;
        const { field: generalField, type } = this.props.comp;
        const { currentRound } = this.props.competition;
        const isSpecial = allowScoreMargin(this.props.comp);

        const field = (currentRound && currentRound.override && currentRound.field) || generalField;

        let selectionValid = false;
        let cleanSelection = undefined;

        if (type === "leaderboard") {

            const nPositions = field.lines.reduce((acc, l) => {
                return acc + l.positions.length;
            }, 0);

            console.log("nPositions", nPositions);

            selectionValid = selections && selections[idInterval] && nPositions === selections[idInterval].length;
            this.setState({
                ...this.state,
                submitError: !selectionValid,
                submitFor: idInterval,
                submitErrorMessage: !selectionValid ? t('select_all_vote') : ''
            });

            if (selectionValid) {
                cleanSelection = selections[idInterval].map(s => ({
                    idPosition: s.position.id,
                    idPlayer: s.player.id,
                }));
            }

        } else if (type === "video-lb") {

            selectionValid = selections && selections.length > 0;

            this.setState({
                ...this.state,
                submitError: !selectionValid,
                submitFor: idInterval,
                submitErrorMessage: !selectionValid ? t('select_a_video') : ''
            });
            const { competition } = this.props;
            let positionID = null;
            if (competition.data.field) {
                if (competition.data.field.lines) {
                    if (competition.data.field.lines[0]) {
                        if (competition.data.field.lines[0].positions) {
                            if (competition.data.field.lines[0].positions) {
                                const positionArr = competition.data.field.lines[0].positions;
                                const defPosObj = positionArr.find(obj => obj.name === 'default-position');
                                if (defPosObj) {
                                    positionID = defPosObj.id;
                                }
                            }
                        }
                    }
                }
            }
            cleanSelection = selections[idInterval].map((s, idx) => ({
                idPosition: positionID,
                idPlayer: s.idVideo,
            }));
        } else if (type === "tipping") {

            if (selections) {
                console.log("idInterval", idInterval)
                cleanSelection = selections[idInterval].map((s) => ({
                    idTeam: s.selection.id,
                    idTippingGame: s.tippingGameId,
                    scoreMargin: !isSpecial || s.selection.id === 1 ? null : s.scoreMargin
                }));
                selectionValid = currentRound.tippingGames.filter(tg => tg.enabled).length === cleanSelection.length;

                // score margin no selected
                const noMargin = cleanSelection.find(obj => obj.idTeam !== 1 && (!obj.scoreMargin || obj.scoreMargin == 0));

                console.log('select margin', selections);
                console.log('clean margin', cleanSelection);
                console.log('no margin', noMargin);

                if (isSpecial && noMargin) {
                    selectionValid = false;
                }

                if (selectionValid) {
                    let tippingUserSelections = getTippingUserSelections();
                    if (tippingUserSelections) {
                        tippingUserSelections = tippingUserSelections.filter(tus => tus.idInterval !== currentRound.id);
                    } else {
                        tippingUserSelections = [];
                    }
                    cleanSelection.forEach(cs =>
                        tippingUserSelections.push({
                            idInterval: currentRound.id,
                            idTeam: cs.idTeam,
                            idTippingGame: cs.idTippingGame,
                            scoreMargin: cs.scoreMargin
                        }))
                    localStorage.setItem('tippingUserSelections', JSON.stringify(tippingUserSelections));
                }

                console.log("selectionValid", selectionValid);
            } else {
                selectionValid = false;
            }
            if (!selectionValid) {
                this.setState({
                    ...this.state,
                    submitError: !selectionValid,
                    submitFor: idInterval,
                    submitErrorMessage: !selectionValid ? t('select_game') : ''
                });
            }
            else {
                this.setState({
                    submitError: false,
                });
            }
            console.log("cleanSelection", cleanSelection);
        }
        if (selectionValid) {
            const data = {
                idInterval,
            };
            if (type !== "tipping") {
                data.votes = cleanSelection;
            } else {
                data.votesTipping = cleanSelection;
            }

            localStorage.removeItem('dynamicRenderUrl');

            this.props.dispatch(submitVote(
                data,
                localStorage.getItem('token'),
                this.props.dispatch
            ));
        }
    }

    handleSelectPlayer = (idPosition) => {

        if (!this.state.loggedIn) {
            this.setState({
                ...this.state,
                willingToPlay: true
            });
            return;
        }

        const currentRound = this.props.competition.currentRound;

        if (currentRound && !currentRound.voteOpen)
            return;

        $('html, body').animate({
            scrollTop: $(".chooser").offset().top
        }, 500);

        this.setState({
            ...this.state,
            selectedPlace: idPosition
        });
    }

    componentDidUpdate(prevProps) {
        const { competition } = this.props;
        const newState = {};
        newState.shares = [];
        if (!prevProps.competition.shareContentUrlBySocialNetworkId
            && competition.shareContentUrlBySocialNetworkId) {
            const roundId = competition.submittedIdInterval;
            let facebookShare = '';
            let twitterShare = '';
            competition.shareContentUrlBySocialNetworkId.forEach(share => {
                if (share && share.id) {
                    switch (share.id) {
                        case 1:
                            facebookShare = share.url;
                            break;
                        case 2:
                            twitterShare = share.url;
                            break;
                    }
                }
            });
            newState.shares.push({ roundId, facebookShare, twitterShare });
            this.setState(newState);
        }

        // process dynamic image render
        const dynamicRenderUrl = localStorage.getItem('dynamicRenderUrl');
        const { currentRound } = this.props.competition;
        if (!prevProps.competition.dynamicRender.dynamicSubmitted
            && competition && competition.data && competition.data.dynamicRenderBackgroundImageUrl
            && (competition.data.type === "leaderboard" || competition.data.type === "video-lb")
            && competition.data.dynamicRenderBackgroundImageUrl !== ''
            && !competition.dynamicRender.dynamicSubmitting
            && !competition.dynamicRender.dynamicSubmitted
            && (!dynamicRenderUrl || dynamicRenderUrl == null)
            && this.props.auth.loggedIn && competition.submitted
            && competition.submittedIdInterval === currentRound.id) {

            const dynamicRenderInput = {
                'idCompetition': competition.data.id,
                'idVote': competition.submittedIdVote,
                'ipAddress': "0.0.0.0",
                'referrer': window.location.protocol + "//" + window.location.host
            }
            this.props.dispatch(dynamicRender(dynamicRenderInput, this.props.dispatch));
        }

    }

    componentWillReceiveProps(nextProps) {

        let newState = {
            ...this.state,
            loggedIn: nextProps.auth.loggedIn,
            user: nextProps.auth.user,
        };

        if (this.props.competition &&
            this.props.competition.currentRound &&
            this.props.competition.currentRound.id !== nextProps.competition.currentRound.id) {

            newState.selectedPlace = null;

        }
        if (nextProps.auth.loggedOffNow) {
            this.state.selections = undefined;
        }

        if (this.props.competition
            && (this.props.competition.showCampaignLoadPopup != nextProps.competition.showCampaignLoadPopup
                || this.state.showCampaignLoadPopup)) {

            newState.popupLoading = false;
            newState.compSelectionData = nextProps.competition.compSelectionData;
            newState.showCampaignLoadPopup = nextProps.competition.showCampaignLoadPopup;

            newState.notLoggedIn = !this.props.auth.loggedIn;
            newState.showLoadCampaign = nextProps.competition.showCampaignLoadPopup;

        }


        this.setState(newState);
    }

    isSocialNetworkEnabledForCompetition = (socialNetworkId) => {
        const { data, currentRound } = this.props.competition;
        if (!this.props.auth.loggedIn || !this.props.competition.submitted
            || this.props.competition.submittedIdInterval !== currentRound.id) {
            return false;
        }

        if (data && data.socialNetworksEnabled) {
            return !!data.socialNetworksEnabled.find(sn => sn.id === socialNetworkId);
        }
        if (data.socialNetworks) {
            return !!data.socialNetworks.find(sn => sn.id === socialNetworkId);
        }
        return false;
    };

    handleTwitterShare = () => {
        this.processShare(2);
    };

    handleFacebookShare = () => {
        this.processShare(1);
    };

    processShare = (idSocialNetwork) => {
        const { competition, comp } = this.props;
        const { currentRound } = competition;

        if (!this.props.auth.loggedIn || !competition.submitted
            || competition.submittedIdInterval !== currentRound.id || !competition.submittedIdVote) {
            return false;
        }

        const { shares } = this.state;
        if (shares && shares.find(s => s.roundId === currentRound.id)) {
            var shareText = competition.socialSharingTextDescription || '';
            const share = shares.find(s => s.roundId === currentRound.id);
            renderShareProcess(share, shareText, idSocialNetwork);
        }
        else {
            const renderData = {
                idCompetition: comp.id,
                idVote: competition.submittedIdVote
            };
            this.props.dispatch(
                renderShare(renderData, this.props.dispatch, idSocialNetwork)
            );
            return;
        }
    };

    trackClick = (idBlock) => {
        const { comp } = this.props;

        let trackClickBody = {
            idCompetition: comp.id,
            type: 'clicks',
            idBlock
        };

        this.props.dispatch(
            trackBlockClick(trackClickBody, this.props.dispatch)
        );
        return;
    };

    handleCloseModal = () => {
        this.setState({
            ...this.state,
            willingToPlay: false
        })
    }

    handleChangeShare = (state, socialShareRef) => {
        const scrollRef = socialShareRef.current
        const currentPosition = scrollRef.scrollLeft;
        const position = window.innerWidth > 650 ? 50 : 73;
        let scrollPosition = state === 'previous' ? Math.round(((currentPosition - position) / position) * position) : Math.round(((currentPosition + position) / position) * position)
        scrollRef.scrollLeft = scrollPosition;
    }


    render() {
        let ret = this.renderMainBlock();

        // let {recoverPass} = this.props.auth;

        // if (this.state.showLoadCampaign) {
        //     ret = this.renderCampaign();
        // }

        return ret;
    }

    renderMainBlock() {
        const { submitError, submitFor, submitErrorMessage } = this.state;

        const { competition, t } = this.props;
        const { currentRound, data, omniBlocks } = competition;
        console.log('compe-ddd', competition);

        console.log('voting-curr', currentRound);

        let voteContOnlyLeaderboard = null;

        let voteCont = (<div className='vote-cont'>
            <div className='loading-rounds'></div>
        </div>);

        let showLoginPanel = false;

        // process dynamic image render
        let { dynamicSubmitting } = competition.dynamicRender ? competition.dynamicRender : competition;
        let dynamicRenderUrl = localStorage.getItem('dynamicRenderUrl');

        let submitButtonText = data && data.submitCopy && data.submitCopy != "" ? data.submitCopy : 'Submit vote';

        let noBorderRadius = (data && data.defaultShape == 2);

        if (data) {

            let shareVote = null;
            // let currentShare = this.state.shares && this.state.shares.find(s => s.roundId === currentRound.id);

            let inviteToVote = null;
            let dynamicRenderBlock = null;

            // let voteSubmitError = submitFor === currentRound.id && submitError ? <div
            //     className='vote-submit-error'>
            //     {submitErrorMessage}
            // </div> : null;

            // if (this.props.competition.submitting) {
            //     // voteSubmitError = (<div
            //     //     className='vote-submit-info'>
            //     //     <FontAwesomeIcon className='benchvote-spin' icon={faSpinner} spin/>
            //     //     Submitting vote...
            //     // </div>);
            //     submitButtonText = 'Submitting';
            // } else if (this.props.competition.submitted && this.props.competition.submittedIdInterval === currentRound.id && this.props.auth.loggedIn) {
            //     // voteSubmitError = (<div
            //     //     className='vote-submit-success'>
            //     //     Thanks, your entry has been saved
            //     // </div>);
            //     submitButtonText = 'Sent';
            // }
            
            if (data.dataCaptureEnabled == 1) {
                showLoginPanel = true;
            }

            const willingDiv = this.state.willingToPlay ? (
                <Modal show={true} onHide={this.handleCloseModal}
                    dialogClassName={'warningModal ' + (noBorderRadius ? 'no-border-radius' : '')}>
                    <Modal.Body>
                        <div className={'logging-in'}>
                            <Trans i18nKey="log_to_vote" />
                            <div className='button-modal' onClick={this.handleCloseModal}>
                                <Trans i18nKey="accept" /><em>></em>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            ) : null;

            let fanSpotData = [
                {
                    imgPath: 'https://benchvote-media-assets.s3.ap-southeast-2.amazonaws.com/demo/fanspot/02_FANSPOT_AGF_REGISTRATION_02.png'
                }
            ];

            console.log('current-123', omniBlocks);

            const omnihubFilters = data.omnihubFilters;

            console.log('ohb-filters', omnihubFilters);

            // console.log('this.props', this.props);
            voteCont = (<div
                style={{ color: data ? data.primaryColor : '' }}
                className='vote-cont'>
                <h1 style={{ color: data ? data.primaryColor : '' }} dangerouslySetInnerHTML={{ __html: data.detail }} className={'mini-site-title'} />
                {data.description && data.description != ''
                    ? <p style={{ color: data ? data.primaryColor : '' }} dangerouslySetInnerHTML={{ __html: data.description }} className={'mini-site-description'} />
                    : ''}
                {/* {voteSubmitError} */}
                {/* {shareVote} */}

                {/* {willingDiv} */}
                {/* {shareVote} */}

                { this.state.popupLoading ?<div className={"loaderCss"}  >
                    <div className={"loader"} ><FadeLoader  size={150}  color={"#ffffff"} loading={this.state.popupLoading}/></div>
                </div>:null }

                <div className='fanspot-content'>
                    {/* for loop check login.js from line 1022 */}
                    {/* <div className='fs-campaign' onClick={this.loadCampaign}>
                        <img src='https://benchvote-media-assets.s3.ap-southeast-2.amazonaws.com/demo/fanspot/02_FANSPOT_AGF_REGISTRATION_02.png' />
                    </div>
                    <div className='fs-campaign'>
                        <img src='https://benchvote-media-assets.s3.ap-southeast-2.amazonaws.com/demo/fanspot/05_FANSPOT_AGF_FINISHED_02.png' />
                    </div>
                    <div className='fs-campaign'>
                        <img src='https://benchvote-media-assets.s3.ap-southeast-2.amazonaws.com/demo/fanspot/05_FANSPOT_AGF_LOBBY_02.png' />
                    </div> */}

                    {omnihubFilters && omnihubFilters.length ?
                        <div className='ohb-filter'>
                            <div className='ohb-filter-name'>SELECT A CONTENT FILTER</div>
                            <div className='ohb-filter-content'>
                                <span 
                                    className={"ohb-filter-item" + (this.state.selectedFilters.length ? '' : ' ohb-selected')}
                                    onClick={(e) => this.filterOmnihubBlock(false, e)}>
                                    ALL
                                </span>
                                <span className='ohb-separator'>/</span>
                                {omnihubFilters
                                    .map((f, fIndex) => {
                                        return (<React.Fragment>
                                            <span 
                                                className={"ohb-filter-item" + (this.isFilterActive(f) ? ' ohb-selected' : '')}
                                                onClick={(e) => this.filterOmnihubBlock(f, e)}>
                                                {f.filter}
                                            </span>
                                            {(fIndex + 1 < omnihubFilters.length)
                                                ? (<span className='ohb-separator'>/</span>)
                                                : ''}
                                        </React.Fragment>)
                                    })
                                }
                            </div>
                        </div>
                        : ''
                    }

                    {omniBlocks
                        .filter(o => o.isBlockEnabled
                            && (new Date(o.blockStartDate) <= new Date() && new Date(o.blockEndDate) >= new Date())
                            && this.isOmnihubBlockHavingFilter(o.filters)
                        )
                        .map(o => {
                            if (o.idBlockType == 5) {
                                return (<div 
                                    style={{ border: '0px' }}
                                    className='fs-campaign' onClick={(e) => this.loadCampaign(o, showLoginPanel, e)}>
                                        <div style={{ backgroundColor: o.blockBgColor ? o.blockBgColor : '',
                                            borderColor: data.borderColor ? data.borderColor : '#ffffff',
                                            color:  o.blockFontColor ? o.blockFontColor : '' }} className='link-block'>
                                            <div className='link-block-image'>
                                                {o.blockImage ? <img src={o.blockImage.location} /> : ''}
                                            </div>
                                            {o.blockTitle && o.blockTitle != '' 
                                                ? <div className='ohb-title-link' dangerouslySetInnerHTML={{ __html: decodeOtherLanguage(o.blockTitle) }}></div> : ''}
                                        </div>
                                </div>)
                            } else {
                                return (<div 
                                    style={{ borderColor: data.borderColor ? data.borderColor : '#ffffff',
                                    backgroundColor: data.buttonColor ? data.buttonColor : '',
                                    color:  data.secondaryColor }}
                                    className='fs-campaign' onClick={(e) => this.loadCampaign(o, showLoginPanel, e)}>
                                        {o.isBlockTitleEnabled && o.blockTitle && o.blockTitle != '' 
                                            ? <div className='ohb-title' dangerouslySetInnerHTML={{ __html: decodeOtherLanguage(o.blockTitle) }}></div> : ''}
                                        {o.isBlockDescriptionEnabled && o.blockDescription && o.blockDescription != '' 
                                            ? <div className='ohb-description' dangerouslySetInnerHTML={{ __html: decodeOtherLanguage(o.blockDescription) }}></div> : ''}
                                        {o.blockImage ? <img src={o.blockImage.location} /> : ''}
                                        {o.idBlockType == 3 && o.blockEmbedCode && o.blockEmbedCode != '' 
                                            ? <div className='ohb-embed-code' dangerouslySetInnerHTML={{ __html: decodeOtherLanguage(o.blockEmbedCode) }}></div> : ''}
                                        {o.idBlockType == 4 && o.blockUrl && o.blockUrl != '' 
                                            ? <div className='ohb-embed-video'>
                                                <iframe src={o.blockUrl} style={{top: 0, left: 0, border: 0}} allowfullscreen allow="encrypted-media;"></iframe>
                                            </div> : ''}
                                        {o.filters && o.filters.length
                                            ? <div className='ohb-filter-line'>
                                                {o.filters
                                                    .map(f => {
                                                        return (<span 
                                                            className={"ohb-filter-line-item" + (this.isFilterActive(f) ? ' ohb-selected' : '')}
                                                            onClick={(e) => this.filterOmnihubBlock(f, e)}>
                                                                {f.filter}
                                                        </span>)
                                                    })
                                                }
                                            </div> : ''}
                                </div>)
                            }
                        })}
                </div>

            </div>)
        }

        console.log('vote-contt', voteCont);

        return (<div className={'parent-vote-cont'}>
            {this.props.showOnlyLeaderboard ? voteContOnlyLeaderboard : voteCont}

            {/*{data && data.type !== 'leaderboard' ?*/}
            {/*<VideoSelector onSelectionChange={this.changeSelection}/> :*/}
            {/*<Field loggedIn={this.props.auth.loggedIn}*/}
            {/*// style={{display: (this.props.auth.loggedIn ? 'block' : 'none')}}*/}
            {/*onSelectionChange={this.changeSelection}/>*/}
            {/*}*/}
            {/* {!this.props.showOnlyLeaderboard && this.state.loggedIn && currentRound && currentRound.voteOpen ?
                (<div className={"vote-btn bottom-btn " + (submitButtonText === "Sent" ? 'bg-green' : '')}
                    style={{
                        backgroundColor: data ? data.buttonColor : '',
                        color: data ? data.primaryColor : ''
                    }}
                    onClick={() => this.handleSubmit(currentRound.id)}>
                    {t(submitButtonText.toLowerCase())}
                </div>) : null} */}

            {this.state.showLoadCampaign && (this.state.loggedIn || !showLoginPanel)
                && (<div className='login-options popup register campaign-loader'>
                {/* <div className='login-content'>
                    <h1><Trans i18nKey="register_for_access" /></h1>
                    <p><Trans i18nKey="reister_leaderboard_picks" /></p>
                </div> */}
                {this.state.ohbData.idBlockType == 2 ? <iframe id='campaign-loader' src={this.state.ohbData.webapp}
                    onLoadCapture={this.passDataToChildToken} /> : ''}
                {/* {this.state.ohbData.idBlockType == 2 ? <iframe id='campaign-loader' src="http://localhost:4000/dist/#2f7c7e98-095b-4a9d-82cd-6648e119ab1e"
                    onLoadCapture={this.passDataToChildToken} /> : ''} */}
                {/* {this.state.ohbData.idBlockType == 2 ? <iframe id='campaign-loader' src="http://localhost:3001/#2b134db8-1097-4091-a4b5-ba0949ebf8a0"
                    onLoadCapture={this.passDataToChildToken} /> : ''} */}
                {/* <iframe id='campaign-loader' src='https://mvp-sandbox.benchvote.com/#5b1e4a7e-90af-4329-8bae-5c5ad55b9306'
                    onLoadCapture={this.passDataToChildToken} /> */}
                {/* {[1,4].includes(this.state.ohbData.idBlockType) ? <iframe src={this.state.ohbData.blockUrl} /> : ''} */}
                {/* <iframe id='campaign-loader' src='http://localhost:4000/dist/#5b1e4a7e-90af-4329-8bae-5c5ad55b9306'
                    onLoadCapture={this.passDataToChildToken} /> */}

                {/* {this.state.ohbData.idBlockType == 2 ? <div className='close' onClick={this.toInitial}> </div> : '' } */}

                {/* <p className='text-uppercase already'><Trans i18nKey="already_have_account" />?<br /><strong onClick={this.toLogin}><Trans i18nKey="login_in_here" /></strong></p> */}
            </div>)}

            {this.state.clickLoadCampaign
                && (<Modal show={this.state.notLoggedIn && showLoginPanel} onHide={this.handleCloseWarning}
                    dialogClassName={'warningModal ' + (noBorderRadius ? 'no-border-radius' : '')}>
                {/* <Modal.Header closeButton>
                </Modal.Header> */}
                <Modal.Body>
                    <div className='warningCopy'>
                        <Trans i18nKey="login_to_participate" />
                        <br />
                    </div>
                    <div className="login-register-btn">
                        {this.renderIniBtns()}
                    </div>
                </Modal.Body>
            </Modal>)}

        </div>);
    }

    toInitial = (event) => {

        console.log('initial-loaded', event);
        if (event.data == 'close') {
            this.setState({
                showLoadCampaign: false,
                clickLoadCampaign: false,
                compSelectionData: {},
                ohbData: {},
                notLoggedIn: !this.state.loggedIn,
                popupLoading: false
            });
        }
    }

    loadCampaign = async (ohbData, showLoginPanel) => {

        this.trackClick(ohbData.id);
        if ([1,5].includes(ohbData.idBlockType)) {
            window.open(ohbData.blockUrl, '_blank', 'noreferrer')
        } else {
            if ([2].includes(ohbData.idBlockType)) {

                if (this.props.auth.loggedIn) {
                    const tokenContent = JSON.parse(localStorage.getItem('tokenContent'));
                    let compselectionData = {
                        idUserOhb: tokenContent.idUser,
                        idCompetition: this.props.competition.data.id,
                        idCompetitionChild: ohbData.idCompetitionTarget
                    };

                    await getAllCompSelectionDataFromOmnihub(compselectionData, this.props.dispatch);
                }

                // this.setState({
                //     notLoggedIn: !this.props.auth.loggedIn,
                //     showLoadCampaign: true,
                //     ohbData: ohbData,
                // });

                let loadCampaignState = {
                    notLoggedIn: !this.props.auth.loggedIn,
                    popupLoading: this.props.auth.loggedIn,
                    clickLoadCampaign: true,
                    ohbData: ohbData,
                };

                if (!showLoginPanel) {
                    loadCampaignState.showLoadCampaign = true;
                }

                this.setState(loadCampaignState);
                
            }
        }

    }

    isFilterActive = (filter) => {
        return this.state.selectedFilters.includes(filter.idFilter ? filter.idFilter : filter.id);
    }

    filterOmnihubBlock = (filter, e) => {

        e.stopPropagation();
        
        let { selectedFilters } = this.state;
        // multiple filter selection
        // selectedFilters.push(filter.id);
        // single filter selection

        if (!filter) {
            selectedFilters = [];
        } else {
            if (filter.idFilter) {
                selectedFilters = [filter.idFilter];
            } else {
                selectedFilters = [filter.id];
            }
        }

        this.setState({
            selectedFilters
        });
    }

    isOmnihubBlockHavingFilter = (filters) => {

        let { selectedFilters } = this.state;

        if (!selectedFilters.length) {
            return true;
        }

        let returnVal = false;

        filters.forEach(f => {
            if (selectedFilters.includes(f.idFilter)) {
                returnVal = true;
            }
        });

        return returnVal;
    }

    // passDataToChild = () => {
    //     console.log('frame loaded');
    //     const message = localStorage.getItem('tokenContent');
    //     const iframe = document.querySelector("iframe");
    //     iframe.contentWindow.postMessage(message, "*");
    // };

    passDataToChildToken = () => {
        console.log('frame loaded');
        const tokenContent = JSON.parse(localStorage.getItem('tokenContent'));
        const message = JSON.stringify({
            isFromOmnihub: true,
            token: localStorage.getItem('token'),
            idCompetitionOhb: this.props.competition.data.id,
            idCompetition: this.state.ohbData.idCompetitionTarget,
            compSelectionData: this.state.compSelectionData,
            ...tokenContent
            });
        console.log('frame-message', message);
        const iframe = document.querySelector("#campaign-loader");
        console.log('frame-content', iframe);
        iframe.contentWindow.postMessage(message, "*");
    };

    handleCloseWarning = () => {
        this.setState({
            notLoggedIn: false,
            showLoadCampaign: false
        });
    }

    renderIniBtns = () => {
        return (<div className='login-options'>
            <div
                className='button margin login-btn' onClick={() => { this.handleCloseWarning(); this.props.onTriggerAppRegisterLogin('login'); }}>
                <Trans i18nKey="login" />
            </div>
            <div
                className='button red_btn' onClick={() => { this.handleCloseWarning(); this.props.onTriggerAppRegisterLogin('register'); }}>
                <Trans i18nKey="signup" />
            </div>
        </div>);
    }

}

//-------------------------------------------------------------------------
//-------------------- Mapping store to App's props ----------------------
//-------------------------------------------------------------------------

const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        competition: things.competition,
        comp: things.competition.data,
        auth: things.auth,
        locale: things.locale
    }
}

const
    mapDispatchToProps = dispatch => {
        return {
            dispatch
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Voting));
