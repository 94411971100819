import Auth from './AuthReducer';
import Competition from './CompetitionReducer';
import TrackingReducer from './TrackingReducer';
import LocaleReducer from "./LocaleReducer";
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: Auth,
    competition: Competition,
    tracking: TrackingReducer,
    locale:LocaleReducer,
});

export default rootReducer;
