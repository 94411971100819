import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, createStore} from 'redux'
import {Provider} from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import {Route} from 'react-router'
import {ConnectedRouter, routerMiddleware, routerReducer} from 'react-router-redux'
import logger from 'redux-logger'
import registerServiceWorker from './registerServiceWorker';
import App from './views/App';
import Widget from './views/Widget';
// import RequireAuth from './views/components/hoc/RequireAuth';
// import NotRequireAuth from './views/components/hoc/NotRequireAuth.js';
// import Signin from './views/components/auth/Signin';
// import Register from './views/components/auth/Register';
import * as reducers from './reducers';
import './index.css';
import './util/helpers/i18n'

import {verifyAuthentication} from "./actions/AuthActions";
import TotalLeaderboard from './views/components/TotalLeaderboard';

const history = createHistory()
const middleware = routerMiddleware(history)
const store = createStore(
    combineReducers({
        ...reducers,
        router: routerReducer
    }),
    applyMiddleware(middleware, logger)
);

const token = localStorage.getItem('token');
if (token) {
    store.dispatch(verifyAuthentication({
        token
    }, store.dispatch));
}

const restrictedNames = ['/widget'];

if ((window && window.location && window.location.hash)
  || window.location.pathname === "/"
  || restrictedNames.includes(window.location.pathname)) {
    console.log('has hash');
    // [hash removal] remove this after hash migration
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div>
                    <Route exact path="/" component={App} />
                    <Route path="/widget" component={Widget} />
                    <Route path="/leaderboard" component={App} />
                    {/*<Route path="/signin" component={NotRequireAuth(Signin)}/>*/}
                    {/*<Route path="/register" component={NotRequireAuth(Register)}/>*/}
                </div>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    )
} else {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div>
                    <Route exact path="/:brand/:act" component={App} />
                    <Route exact path="/:brand/:act/widget" component={Widget} />
                    <Route exact path="/:brand/:act/leaderboard" component={App} />
                    {/*<Route path="/signin" component={NotRequireAuth(Signin)}/>*/}
                    {/*<Route path="/register" component={NotRequireAuth(Register)}/>*/}
                </div>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    )
}

registerServiceWorker();
