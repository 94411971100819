import {
    emailConfirmation, login, loginFace,
    logoff, register, retrieveTwitterAccessToken,
    retrieveTwitterToken, reValidateToken,
    forgotCall, resetCall, optinApi, optinSocialApi, webhookRegisterApi
} from '../util/api_calls/ProjectApiCalls';
import {decodeJWT} from '../util/helpers/JWTHelper'

const verifyAuthentication = (payload, dispatch) => {
    if (!payload.token)
        return;

    reValidateToken(
        payload,
        payload => {
            dispatch(verifyAuthenticationFinish(payload))
        },
        error => {
            dispatch(verifyAuthenticationError(error))
        }
    );

    return {
        type: 'VERIFING_AUTHENTICATION',
        payload: {}
    }
}

const verifyAuthenticationFinish = payload => {

    let {token} = payload;

    let tokenContent = decodeJWT(token);

    return {
        type: 'POST_AUTHENTICATE_FINISH_2',
        payload: {
            ...payload,
            tokenContent: tokenContent
        }
    }
}

const verifyAuthenticationError = payload => {

    return {
        type: 'VERIFING_AUTHENTICATION_ERROR',
        payload: {
            ...payload
        }
    }
}

const signOff = (dispatch) => {

    logoff(
        {},
        payload => {
            dispatch(signOffFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'POST_UNAUTHENTICATE_START',
        payload: {}
    }
}


const signOffFinish = payload => {

    localStorage.removeItem('token');
    localStorage.removeItem('tokenContent');
    localStorage.removeItem('tippingUserSelections');
    localStorage.removeItem('tippingPosition');
    localStorage.removeItem('tippingScore');

    window.location.reload();

    return {
        type: 'POST_UNAUTHENTICATE_FINISH',
        payload: {
            ...payload
        }
    }
}

const optinCall = (parameters, token, dispatch) => {

    optinApi(
        parameters, token,
        payload => {
            dispatch(optinFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'OPTIN_START',
        payload: parameters
    }
}


const optinSocialCall = (parameters, token, dispatch) => {

    optinSocialApi(
        parameters, token,
        payload => {
            dispatch(optinFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'OPTIN_START',
        payload: parameters
    }
}


const optinFinish = payload => {

    return {
        type: 'OPTIN_FINISH',
        payload: {
            ...payload
        }
    }
}

const startReset = (parameters, dispatch) => {

    return {
        type: 'START_RESET_START',
        payload: parameters
    }
}

const verifyEmail = (parameters, dispatch) => {

    emailConfirmation(
        parameters,
        payload => {
            dispatch(verificationFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'EMAIL_VERIFICATION_START',
        payload: parameters
    }
}

const verificationFinish = payload => {

    return {
        type: 'EMAIL_VERIFICATION_FINISH',
        payload: {
            ...payload
        }
    }
}

const signUp = (parameters, dispatch) => {

    register(
        parameters,
        payload => {
            dispatch(registerSignInFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'POST_SIGNUP_START',
        payload: parameters
    }
}

const webhookRegister = (parameters, dispatch) => {

    webhookRegisterApi(
        parameters,
        payload => {
            dispatch(webhookRegisterFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'POST_SIGNUP_START',
        payload: parameters
    }
}

const signUpFinish = payload => {

    return {
        type: 'POST_SIGNUP_FINISH',
        payload: {
            ...payload
        }
    }
}

const signIn = (parameters, dispatch) => {

    login(
        parameters,
        payload => {
            dispatch(signInFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'POST_AUTHENTICATE_START',
        payload: parameters
    }
}


const forgot = (parameters, dispatch) => {

    forgotCall(
        parameters,
        payload => {
            dispatch(forgotFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'FORGOT_START',
        payload: parameters
    }
}


const forgotFinish = (parameters, dispatch) => {

    return {
        type: 'FORGOT_FINISH',
        payload: parameters
    }
}


const reset = (parameters, dispatch) => {

    resetCall(
        parameters,
        payload => {
            dispatch(resetFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'RESET_START',
        payload: parameters
    }
}


const resetFinish = (parameters, dispatch) => {

    return {
        type: 'RESET_FINISH',
        payload: parameters
    }
}

const signInTwitterFinish = (parameters, dispatch) => {

    retrieveTwitterAccessToken(
        parameters,
        payload => {
            dispatch(signInFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'TWITTER_LOGIN_CONTINUE',
        payload: parameters
    }
}

const signInTwitter = (parameters, dispatch) => {

    retrieveTwitterToken(
        parameters,
        payload => {
            dispatch(twitterTokenRetrieved(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'TWITTER_LOGIN_START',
        payload: parameters
    }
}

const twitterTokenRetrieved = payload => {

    return {
        type: 'TWITTER_LOGIN_FINISH',
        payload: {
            ...payload
        }
    }
}

const signInFace = (parameters, dispatch) => {

    loginFace(
        parameters,
        payload => {
            dispatch(signInFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'POST_AUTHENTICATE_START',
        payload: parameters
    }
}

const signInFinish = payload => {

    let {token, tippingUserSelections, tippingPosition, tippingScore} = payload;

    let tokenContent = decodeJWT(token);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenContent', JSON.stringify(tokenContent));

    if (tippingUserSelections) {
        localStorage.setItem('tippingUserSelections', JSON.stringify(tippingUserSelections));
    }
    if (tippingPosition) {
        localStorage.setItem('tippingPosition', tippingPosition);
        localStorage.setItem('tippingScore', tippingScore);
    }

    return {
        type: 'POST_AUTHENTICATE_FINISH',
        payload: {
            ...payload,
            tokenContent: tokenContent
        }
    }
}

const webhookRegisterFinish = payload => {
    return {
        type: 'WEBHOOK_REGISTER_FINISH',
        payload: {
            ...payload,
        }
    }
}

const registerSignInFinish = payload => {

    let {token} = payload;

    let tokenContent = decodeJWT(token);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenContent', JSON.stringify(tokenContent));

    return {
        type: 'POST_LOGIN_AUTHENTICATE_FINISH',
        payload: {
            ...payload,
            tokenContent: tokenContent
        }
    }
}

const postError = error => {

    console.error("error", error);
    let payload = {}
    if (error.constructor.name === 'Error') {
        // In case 500 is received I don't want to show server's raw error.
        payload = {
            errorMessages: ['There was an error, please contact support.']
        };
    } else {
        payload = {...error};
    }
    return {
        type: "POST_AUTHENTICATE_ERROR",
        payload: payload
    }
}

const isSpecialComp = comp => {
    return (comp && [2268, 2301, 4954, 4555].includes(comp.id));
}

const allowScoreMargin = comp => {
    return (comp && comp.useScoreMargin);
}

const getPassword = idComp => {
    return `aBcUpl*I19#l35_${idComp}`;
}

const getEmail = (username, idComp) => {
    return `cw_${idComp}_${username}@campaignware.com`;
}

export {
    signIn, signInFace, signInTwitter,
    signInTwitterFinish, signUp, signOff,
    verifyEmail, verifyAuthentication, forgot,
    startReset, reset, optinCall, optinSocialCall, webhookRegister,
    isSpecialComp, allowScoreMargin, getPassword, getEmail
}
