import React, {Component} from 'react';

import './Header.css';
import connect from "react-redux/es/connect/connect";

class Header extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    render() {

        const {comp: {data}} = this.props;

        return (
            <div className='header'>
                {data && (data.logoBg || (data.sponsorsBg && data.sponsorsTop)) ? (
                    <div>
                        {data.logoBg ? (
                            <div className={'logos-cont'}>
                                <img src={data.logoBg.file} className={'pl-logo logo-image center-block'}/>
                            </div>
                        ) : null}
                        {data.sponsorsBg && data.sponsorsTop ? (data.sponsorsLink && data.sponsorsLink != '') ? (
                            <a href={data.sponsorsLink} target="_blank">
                                <div className={'logos-cont'}>
                                    <img src={data.sponsorsBg.file} className={'pl-logo logo-image sponsors'}/>
                                </div>
                            </a>
                        ) : (
                            <div className={'logos-cont'}>
                                <img src={data.sponsorsBg.file} className={'pl-logo logo-image sponsors'}/>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        comp: things.competition,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
