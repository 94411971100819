import {getActiveCompetitions, getLeaderboardsApi, submit, getTippingStandingsApi,
    renderShareApiCall, dynamicRenderApiCall, getTippingStandingsAuthenticatedApi, getLeaderboardStandingsAuthenticatedApi,
    getLeaderboardStandingsApi, getOmnihubBlocksApi, getFootersForCompApi, trackBlockClickApiCall, getAllCompSelectionDataFromOmnihubApi, 
    getGlobalScriptsForCustomerApi} from '../util/api_calls/ProjectApiCalls';
import {decodeOtherLanguage} from "../util/helpers/Helpers";

const getAllRounds = (hash, dispatch) => {

    getActiveCompetitions(
        hash,
        payload => {
            if (payload.hasOwnProperty('legacyCharEncoding') && payload.legacyCharEncoding == 0) {
                payload.detail = decodeOtherLanguage(payload.detail);
                payload.description = decodeOtherLanguage(payload.description);
                payload.lastPageCopy = decodeOtherLanguage(payload.lastPageCopy);
                payload.thanksCopy = decodeOtherLanguage(payload.thanksCopy);
                payload.drawCopy = decodeOtherLanguage(payload.drawCopy);
                }
            dispatch(getAllRoundsFinish(payload.hash, payload, dispatch))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'GET_ALL_ROUNDS_START',
        payload: {}
    }
}

const selectRound = (newRound, dispatch) => {

    return {
        type: 'SELET_NEW_ROUND',
        payload: newRound
    }
}

const submitVote = (payload, token, dispatch) => {
    submit(
        payload, token,
        payload => {
            dispatch(submitVoteFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'SUBMIT_VOTE_START',
        payload: payload
    }
}

const submitVoteFinish = (payload) => {

    return {
        type: 'SUBMIT_VOTE_FINISH',
        payload: payload
    }
}

const getAllRoundsFinish = (hash, payload, dispatch) => {

    console.log("Looking for leaderboard after Round...");

    // setTimeout(() => {
        dispatch(getOmnihubBlocks(payload.id, dispatch));
        dispatch(getFootersForComp(payload.id, dispatch));
    // }, 1000);

    return {
        type: 'GET_ALL_ROUNDS_FINISH',
        payload: {
            ...payload
        }
    }
}

const postError = error => {

    console.error("error", error);
    let payload = {}
    if (error.constructor.name === 'Error') {
        // In case 500 is received I don't want to show server's raw error.
        payload = {
            errorMessages: ['There was an error, please contact support.']
        };
    } else {
        payload = {...error};
    }
    return {
        type: "POST_ERROR",
        payload: payload
    }
}

const getOmnihubBlocks = (idCompetition, dispatch) => {

    getOmnihubBlocksApi(
        idCompetition,
        payload => {
            dispatch(getOmnihubBlocksFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'GET_OMNIBLOCKS_START',
        payload: {}
    }
}

const getOmnihubBlocksFinish = payload => {

    return {
        type: 'GET_OMNIBLOCKS_FINISH',
        payload
    }
}

const getFootersForComp = (idCompetition, dispatch) => {

    getFootersForCompApi(
        idCompetition,
        payload => {
            dispatch(getFootersForCompFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'GET_FOOTER_START',
        payload: {}
    }
}

const getFootersForCompFinish = payload => {

    return {
        type: 'GET_FOOTER_FINISH',
        payload
    }
}

const getLeaderboards = (hash, dispatch) => {

    getLeaderboardsApi(
        hash,
        payload => {
            dispatch(getLeaderboardsFinish(payload))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'GET_LEADERBOARDS_START',
        payload: {}
    }
}

const getLeaderboardsFinish = payload => {

    return {
        type: 'GET_LEADERBOARDS_FINISH',
        payload
    }
}

const getLeaderboardStandings = (idCompetition, token, data, dispatch) => {

    if (data && data.authenticated) {
        getLeaderboardStandingsAuthenticatedApi(
            idCompetition,
            token,
            data,
            payload => {
                dispatch(getLeaderboardStandingsFinish(payload))
            },
            error => {
                dispatch(postError(error))
            }
        );
    } else {
        getLeaderboardStandingsApi(
            idCompetition,
            token,
            data,
            payload => {
                dispatch(getLeaderboardStandingsFinish(payload))
            },
            error => {
                dispatch(postError(error))
            }
        );
    }


    return {
        type: 'GET_LEADERBOARD_STANDINGS_START',
        payload: {}
    }
}

const getLeaderboardStandingsFinish = payload => {

    // if (payload && payload.userData && payload.userData.rank) {
    //     localStorage.setItem('tippingPosition', payload.userData.rank);
    //     localStorage.setItem('tippingScore', payload.userData.score);
    // }

    return {
        type: 'GET_LEADERBOARD_STANDINGS_FINISH',
        payload
    }
}

const getTippingStandings = (idCompetition, token, data, dispatch) => {

    if (data && data.authenticated) {
        getTippingStandingsAuthenticatedApi(
            idCompetition,
            token,
            data,
            payload => {
                dispatch(getTippingStandingsFinish(payload))
            },
            error => {
                dispatch(postError(error))
            }
        );
    } else {
        getTippingStandingsApi(
            idCompetition,
            token,
            data,
            payload => {
                dispatch(getTippingStandingsFinish(payload))
            },
            error => {
                dispatch(postError(error))
            }
        );
    }


    return {
        type: 'GET_TIPPING_STANDINGS_START',
        payload: {}
    }
}

const getTippingStandingsFinish = payload => {

    if (payload && payload.userData && payload.userData.rank) {
        localStorage.setItem('tippingPosition', payload.userData.rank);
        localStorage.setItem('tippingScore', payload.userData.score);
    }

    return {
        type: 'GET_TIPPING_STANDINGS_FINISH',
        payload
    }
}

const executeShare = (shareUrl, shareText, idSocialNetwork) => {
    switch (idSocialNetwork) {
        case 1:
            if (shareUrl) {
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl),
                    'facebook-share-dialog', 'width=626,height=436'
                );
            }
            break;
        case 2:
            if (shareUrl) {
                window.open('http://www.twitter.com/intent/tweet?url=' +
                    encodeURIComponent(shareUrl) + '&text=' + encodeURIComponent(shareText),
                    'twitter-share-dialog', 'width=626,height=436'
                );
            }
            break;
    }
};

const renderShareProcess = (share, shareText, idSocialNetwork) => {
  if (share) {
    switch (idSocialNetwork) {
        case 1:
            executeShare(share.facebookShare, shareText, idSocialNetwork);
            break;
        case 2:
            executeShare(share.twitterShare, shareText, idSocialNetwork);
            break;
    }
  }
};

const renderShareProcessAsync = (enabledSocialNetworks, idSocialNetwork) => {
  if (enabledSocialNetworks) {
    var shareUrl = '';
    var shareText = '';
    enabledSocialNetworks.forEach(function (sn) {
      if (sn.id && sn.id === idSocialNetwork) {
        shareUrl = sn.url;
        shareText = sn.copy;
      }
    });

    if (shareUrl != '') {
        executeShare(shareUrl, shareText,idSocialNetwork);
    }
  }
};


const renderShare = (body, dispatch, idSocialNetwork) => {
  renderShareApiCall(
    body,
    payload => {
      dispatch(renderShareFinish(payload, idSocialNetwork));
    },
    error => {
      dispatch(postError(error));
    }
  );

  return {
    type: "RENDER_SHARE_START",
    payload: {}
  };
};

const renderShareFinish = (payload, idSocialNetwork) => {

  if (payload.data && payload.data.enabledSocialNetworks) {
    renderShareProcessAsync(payload.data.enabledSocialNetworks, idSocialNetwork);
  }

  return {
    type: "RENDER_SHARE_FINISH",
    payload: {
      ...payload.data
    }
  };
};

const trackBlockClick = (body, dispatch) => {
  trackBlockClickApiCall(
    body,
    payload => {
      dispatch(trackBlockClickFinish(payload));
    },
    error => {
      dispatch(postError(error));
    }
  );

  return {
    type: "TRACK_BLOCK_CLICK_START",
    payload: {}
  };
};

const trackBlockClickFinish = (payload) => {

  return {
    type: "TRACK_BLOCK_CLICK_FINISH",
    payload: {
      ...payload.data
    }
  };
};

const dynamicRender = (body, dispatch) => {
  dynamicRenderApiCall(
    body,
    payload => {
      dispatch(dynamicRenderFinish(payload));
    },
    error => {
      dispatch(postError(error));
    }
  );

  return {
    type: "DYNAMIC_RENDER_START",
    payload: {}
  };
};

const dynamicRenderFinish = (payload) => {

  if (payload && payload.data && payload.data.dynamicRenderUrl) {
    localStorage.setItem('dynamicRenderUrl', payload.data.dynamicRenderUrl);
  }

  return {
    type: "DYNAMIC_RENDER_FINISH",
    payload: {
      ...payload
    }
  };
};

const getAllCompSelectionDataFromOmnihub = (body, dispatch) => {
  getAllCompSelectionDataFromOmnihubApi(
    body,
    payload => {
      dispatch(getAllCompSelectionDataFromOmnihubFinish(payload));
    },
    error => {
      dispatch(postError(error));
    }
  );

  return {
    type: "GET_COMP_SELECTION_START",
    payload: {}
  };
};

const getAllCompSelectionDataFromOmnihubFinish = (payload) => {

  return {
    type: "GET_COMP_SELECTION_FINISH",
    payload: {
      ...payload
    }
  };
};

const getGlobalScriptsForCustomer = (hash, dispatch) => {

    getGlobalScriptsForCustomerApi(
        hash,
        payload => {
            dispatch(getGlobalScriptsForCustomerFinish(payload.hash, payload, dispatch))
        },
        error => {
            dispatch(postError(error))
        }
    );

    return {
        type: 'GET_GLOBAL_SCRIPTS_START',
        payload: {}
    }
}

const getGlobalScriptsForCustomerFinish = (hash, payload, dispatch) => {

    console.log("Looking for global scripts for customer...");

    return {
        type: 'GET_GLOBAL_SCRIPTS_FINISH',
        payload: {
            ...payload
        }
    }
}

export {getAllRounds, selectRound, submitVote, getLeaderboards, getTippingStandings,
    renderShare, renderShareProcess, renderShareProcessAsync, dynamicRender, getLeaderboardStandings,
    getOmnihubBlocks, trackBlockClick, getAllCompSelectionDataFromOmnihub,
    getGlobalScriptsForCustomer }
