import React, { Component } from 'react';

import './Footer.css';
import connect from "react-redux/es/connect/connect";
import { Trans, withTranslation } from 'react-i18next';
import { decodeOtherLanguage, showEmbedFooter } from '../../util/helpers/Helpers';

class Footer extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const hasEmbedFooter = showEmbedFooter();
        if (hasEmbedFooter) {
            const { comp: { data } } = this.props;
            window.parent.postMessage({
                type: "CAMPAIGNWARE-FOOTER",
                showMadeWithBv: data ? data.showMadeWithBv : '',
                privacypolicy: data ? data.privacypolicy : '',
                privacypolicyTxt: this.props.t('privacy_policy'),
                tncs: data ? data.tncs : '',
                tncsTxt: this.props.t('term_condition'),
                url: 'https://www.campaignware.com/?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page'
            },
                "*");
        }
    }

    scrollToTop = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }

    isFooterCopyrightPresent = (footers) => {
        const footerCopyright = footers.find(
            f => f.footerType == 'copyrightText' && f.footerCopyright && f.footerCopyright != ''
        );
        if (footerCopyright) {
            return true;
        }
        return false;
    }

    render() {

        const { comp: { data, footers } } = this.props;
        // temp fix. remove it
        // if (data) {
        //     data.showMadeWithBv = false;
        // }
        const hasEmbedFooter = showEmbedFooter();

        return data ? (
            <div className='footer'>
                <div className={'footer-icon-block'}>
                    {footers ? 
                        footers
                            .filter(f => f.footerType == 'scrollIcon' && f.location != '')
                            .map(f => {
                                return (
                                    <div className={'footer-icon ' + 'footer-icon-' + f.id}>
                                        <a onClick={(e) => this.scrollToTop(e)} target='_blank'>
                                            <img src={f.location} />
                                        </a>
                                    </div>
                                )
                            })
                        : null}
                    {footers ?
                        footers
                            .filter(f => f.footerType == 'link' && f.location != '')
                            .map(f => {
                                return (
                                    <div className={'footer-icon ' + 'footer-icon-' + f.id}>
                                        <a href={f.footerUrl} target='_blank'>
                                            <img src={f.location} />
                                        </a>
                                    </div>
                                )
                            })
                        : null}
                </div>
                {footers && this.isFooterCopyrightPresent(footers) ? <div className={'footer-copyright-block'}>
                    {footers
                        .filter(f => f.footerType == 'copyrightText' && f.footerCopyright != '')
                        .map(f => {
                            return (
                                <div dangerouslySetInnerHTML={{ __html: decodeOtherLanguage(f.footerCopyright) }}>
                                </div>
                            )
                        })}
                </div> : null}
                <div>
                    {data.sponsorsBg && !data.sponsorsTop ? (data.sponsorsLink && data.sponsorsLink != '') ?
                        <div>
                            <a href={data.sponsorsLink} target="_blank">
                                <div className={'logos-cont'}>
                                    <img src={data.sponsorsBg.file} className={'pl-logo logo-image sponsors'} />
                                </div>
                            </a>
                        </div> : <div>
                            <div className={'logos-cont'}>
                                <img src={data.sponsorsBg.file} className={'pl-logo logo-image sponsors'} />
                            </div>
                        </div> : null}
                    {!hasEmbedFooter && (data.tncs || data.privacypolicy || data.showMadeWithBv) ? <div className={'fixed-container'}>
                        {data.tncs || data.privacypolicy || data.showMadeWithBv ?
                            <div className={'floatingFooter ' + (!data.tncs && !data.privacypolicy ? 'floatingFooterHide' : '')}>

                                {data.tncs ?
                                    <a href={data.tncs}

                                        target="_blank"><Trans i18nKey="term_condition" /></a>
                                    : null}
                                {data.tncs ? ' - ' : null}
                                {data.privacypolicy ?
                                    <a href={data.privacypolicy}

                                        target="_blank"><Trans i18nKey="privacy_policy" /></a>
                                    : null}
                            </div> : null}
                        {data.showMadeWithBv ?
                            <div className='made-with-bv'>
                                <div className='made-with-bv-desktop'>
                                    <a href="https://www.campaignware.com/?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page"
                                        target="_blank" className="btn btn-sm p-l-20 p-r-20" >
                                        <img className="footer-logo" src="https://benchvote-media-assets.s3-ap-southeast-2.amazonaws.com/common-images/cw_by_bv_white.png" /></a>

                                </div>
                                <div className='made-with-bv-mobile'>
                                    <div class="build-with-bv">
                                        <a href="https://www.campaignware.com/?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page"
                                            target="_blank">
                                            <img className="footer-logo" src="https://benchvote-media-assets.s3-ap-southeast-2.amazonaws.com/common-images/cw_by_bv_white.png" /></a>
                                    </div>

                                </div>
                            </div> : null}
                    </div> : null}
                </div>
            </div>
        ) : null;
    }
}


const mapStateToProps = (state, ownProps) => {

    const things = state.default;
    return {
        ...ownProps,
        comp: things.competition,
        locale: things.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer));
